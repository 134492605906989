import React, { ErrorInfo } from 'react';
import { Toaster, Position, Intent } from '@blueprintjs/core';

const toaster = Toaster.create({
    position: Position.TOP,
});

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error, errorInfo });

        // Show error in a toaster
        toaster.show({
            message: (
                <>
                    <strong>Error:</strong> {error.message}
                    <br />
                    <strong>Location:</strong> {errorInfo.componentStack}
                </>
            ),
            intent: Intent.DANGER,
            timeout: 5000,
        });
    }

    render() {
        if (this.state.hasError) {
            // Optionally, render a fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}