import { ChartOptions } from 'chart.js';
import { SHORT_UNIT } from '../timescale/timeunits.js';
export default interface ChartDataStore {
    cases: number[][]; // array of case ids for each chart time interval
    xlabels: string[];
    values: number[];
    durations: (number | null)[];
    xunit: SHORT_UNIT;
}

export type ChartType = 'START_INTERVAL' | 'COVER_INTERVAL' | 'RUNTIME_DIST';
export type MinMax = { min: number, max: number };

export type TimeChartOptions = ChartOptions<'bar'> & ChartOptions<'line'> & {
    type: ChartType;
    clampToZero: boolean;
    fixMinMax: boolean;
    minmaxNumber: MinMax;
    minmaxDuration: MinMax;
}

export type PartialTimeChartOptions = Partial<TimeChartOptions>;

export function isChartDataStore(data: unknown): data is ChartDataStore {
    return (
        typeof data == "object" && data !== null &&
        "cases" in data && Array.isArray(data.cases) &&
        data.cases.every((intervalCases: unknown) => {
            return (
                Array.isArray(intervalCases) &&
                intervalCases.every(caseId => typeof caseId === "number"))
        }) &&
        "xlabels" in data && Array.isArray(data.xlabels) && data.xlabels.every((label: unknown) => typeof label === "string") &&
        "values" in data && Array.isArray(data.values) && data.values.every((value: unknown) => typeof value === "number") &&
        "durations" in data && Array.isArray(data.durations) && data.durations.every((value: unknown) => typeof value === "number" || value === null) &&
        "xunit" in data && typeof data.xunit === "string"
    )
}
