import DataController, { DataControllerOptions } from './DataController.js';
import { Decycler, VersionedValue } from "@insight/common/decycler/Decycler.js";
import { ISOrg } from "@insight/common/orgstructure/ISOrg.js";
import { OrgStructure } from '@insight/common/orgstructure/OrgStructure.js';
import { Serializable } from 'child_process';
OrgStructure;

class OrgStructureController extends DataController<ISOrg | null> {

    constructor(options: DataControllerOptions) {
        super(null, options);
    }

    toJSON() {
        const decycler = new Decycler();
        const result = decycler.decycle(this.data);
        return result;
    }

    fromJSON(pojso: VersionedValue): ISOrg {
        const decycler = new Decycler();
        const result: ISOrg = decycler.retrocycle(pojso) as ISOrg;
        return result;
    }
}
Decycler.registerSerializableType(OrgStructureController);
export default OrgStructureController;