import numeral from "numeral";
import { DateTime, Duration } from "luxon"

import { CaseFilterFieldDefinition, FieldType } from "./CaseFilterFieldDefinition.js";
import { CaseFilter } from "./CaseFilter.js";

import { Decycler } from "@insight/common/decycler/Decycler.js";
import { IntlShape } from "react-intl";

export type CaseFilterPrimitiveFieldValue = string | boolean | number;
export type CaseFilterFieldValue = CaseFilterPrimitiveFieldValue | CaseFilterPrimitiveFieldValue[] | undefined;

/**
 * A case filter field has a single primitive value or an array of primitive values depending on the 
 * case filter field definition vector property. The possible primitive types are boolean, number, string. 
 * Dates or Durations are represented as epoch values (ms sincd 1970).
 */
export class CaseFilterField {

    /** the case filter this field belongs to */
    filter: CaseFilter;

    /** the field definition */
    fieldDefinitionCopy: CaseFilterFieldDefinition

    /** the current field value, undefined if not yet set */
    value: CaseFilterFieldValue

    /** a cache for the current UI value */
    private uiValueCache: string | string[] | undefined

    /** set if value was tried to set. undefined otherwise */
    valid?: boolean; 

    /** set if valid === false */
    validationErrorReason?: string;

    constructor(name: string, filter: CaseFilter, definition: CaseFilterFieldDefinition) {
        this.filter = filter;
        this.fieldDefinitionCopy = new (definition.constructor as typeof CaseFilterFieldDefinition)(definition);
        this.value = definition.initialValue;
        this.uiValueCache = undefined;
    }

    copy() {
        const field = new CaseFilterField(this.fieldDefinitionCopy.id, this.filter, this.fieldDefinitionCopy);
        field.value = this.copyValue()
        field.uiValueCache = this.uiValueCache;
        field.valid = this.valid;
        field.validationErrorReason = this.validationErrorReason;
        return field;
    }

    copyValue() {
        let value: CaseFilterFieldValue
        if (Array.isArray(this.value)) {
            value = [...this.value];
        }
        else {
            value = this.value;
        }
        return value;
    }

    getUIValue(locale: string) {
        if (this.uiValueCache !== undefined) return this.uiValueCache;
        this.uiValueCache = this.toString(locale)
        return this.uiValueCache;
    }

    /**
     * Verify the type of a value agaist the field type and set the field value.
     * If the type is wrong, the field is set to invalid and a reason is given.
     * @param value
     */
    set(value: CaseFilterFieldValue | undefined) {
        if (
            ((!Array.isArray(value) || this.fieldDefinitionCopy.vector) && this.validateType(value) !== false)
            || (value === undefined && !this.fieldDefinitionCopy.required)
        ) {
            this.valid = true;
            this.value = value;
        }
        else {
            this.value = undefined;
            this.valid = false;
            this.validationErrorReason = "Invalid value type";
        }
        this.uiValueCache = undefined;
    }

    /**
     *
     * @param stringValue
     */
    fromString(stringValue: string | string[], intl: IntlShape) {

        const invalidateValue = (msg: string) => {
            this.valid = false;
            this.validationErrorReason = msg;
        }

        this.uiValueCache = stringValue;
        let v: CaseFilterFieldValue;
        if (!Array.isArray(stringValue)) {
            v = this.singleFromString(stringValue, intl.locale);
        }
        else {

            v = stringValue.reduce<CaseFilterPrimitiveFieldValue[] | undefined>((value, item) => {
                if (value !== undefined) {
                    v = this.singleFromString(item, intl.locale)
                    if (v !== undefined) {
                        value.push(v);
                    }
                    else {
                        value = undefined;
                    }
                }
                return value;
            }, [])
        }

        if (v !== undefined) {
            this.valid = true;
            this.validationErrorReason = undefined;
            this.value = v;
        }
        else {
            switch (this.fieldDefinitionCopy.type) {
                case FieldType.BOOLEAN:
                    invalidateValue(intl.formatMessage({
                        id: "filter.msg.not_a_valid_boolean",
                        defaultMessage: "{value} is not a valid boolean",
                        description: "Error message not a valid boolean",
                    }, { value: stringValue as string }));
                    break;

                case FieldType.INTEGER:
                    invalidateValue(intl.formatMessage({
                        id: "filter.msg.not_a_valid_integer",
                        defaultMessage: "{value} is not a valid integer",
                        description: "Error message not a valid integer.",
                    }, { value: stringValue as string }));
                    break;

                case FieldType.FLOAT:
                    invalidateValue(intl.formatMessage({
                        id: "filter.msg.not_a_float",
                        defaultMessage: "{value} is not a valid float",
                        description: "Error message not a valid floating point number.",
                    }, { value: stringValue as string }));
                    break;

                case FieldType.STRING:
                    invalidateValue(intl.formatMessage({
                        id: "filter.msg.not_a_valid_string",
                        defaultMessage: "{value} is not a valid string",
                        description: "Error message not a valid string",
                    }, { value: stringValue as string }));
                    break;

                case FieldType.DURATION:
                    invalidateValue(intl.formatMessage({
                        id: "filter.msg.not_a_valid_duration",
                        defaultMessage: "{value} is not a valid ISO duration",
                        description: "Error message not a valid ISO duration",
                    }, { value: stringValue as string }));
                    break;

                case FieldType.DATE:
                    invalidateValue(intl.formatMessage({
                        id: "filter.msg.not_a_valid_date",
                        defaultMessage: "{value} is not a valid ISO date",
                        description: "Error message not a valid ISO date",
                    }, { value: stringValue as string }));
                    break;
            }
        }

    }

    private singleFromString(value: string, locale?: string): CaseFilterPrimitiveFieldValue | undefined {
        let result: CaseFilterPrimitiveFieldValue | undefined;
        switch (this.fieldDefinitionCopy.type) {
            case FieldType.BOOLEAN: {
                if (['true', 'false'].includes(value)) {
                    result = value === "true" ? true : false;
                }
                break;
            }

            case FieldType.INTEGER: {
                const tmp = Number.parseInt(value);
                if (Number.isInteger(tmp) && tmp.toString() === value) {
                    result = tmp;
                }
                break;
            }

            case FieldType.FLOAT: {
                const v = numeral(value).value();
                if (v !== null) result = v;
                break;
            }

            case FieldType.STRING: {
                result = value;
                break;
            }

            case FieldType.DURATION: {
                const tmp = Duration.fromISO(value);
                if (tmp.isValid) {
                    result = tmp.toMillis();
                }
                break;
            }

            case FieldType.DATE: {
                let tmp: DateTime;
                if (this.fieldDefinitionCopy.format !== undefined) {
                    let opts = {};
                    if (locale !== undefined) {
                        opts = { locale }
                    }
                    tmp = DateTime.fromFormat(value, this.fieldDefinitionCopy.format, opts)
                }
                else {
                    tmp = DateTime.fromISO(value);
                }
                if (tmp.isValid) {
                    result = tmp.toMillis();
                }
                break;
            }

            default: {
                result = undefined;
            }
        }
        return result;
    }

    toString(locale: string) {
        if (!Array.isArray(this.value)) {
            return this.singleToString(this.value, locale)
        }
        else {
            return `[${this.value.map(v => `"${v}"`).join(", ")}]`;
        }
    }

    private singleToString(value: CaseFilterFieldValue, locale: string): string {
        let result: string = "";
        if (value !== undefined) {
            switch (this.validateType(value)) {
                case FieldType.BOOLEAN: {
                    result = value.toString();
                    break;
                }
                case FieldType.INTEGER: {
                    result = value.toString();
                    break;
                }

                case FieldType.FLOAT: {
                    if (this.fieldDefinitionCopy.format !== undefined) {
                        result = numeral(value).format(this.fieldDefinitionCopy.format)
                    }
                    else {
                        result = new Intl.NumberFormat(locale).format(value as number)
                    }
                    break;
                }

                case FieldType.STRING: {
                    result = `${value}`;
                    break;
                }

                case FieldType.DATE: {
                    this.value = value;
                    const dt = DateTime.fromMillis(value as number);
                    if (this.fieldDefinitionCopy.format !== undefined) {
                        let opts = {};
                        if (locale !== undefined) opts = { locale }
                        result = dt.toFormat(this.fieldDefinitionCopy.format, opts)
                    }
                    else if (locale !== undefined) {
                        result = dt.setLocale(locale).toLocaleString();
                    }
                    else {
                        result = dt.toLocaleString();
                    }
                    break;
                }

                case FieldType.DURATION: {
                    this.value = value;
                    result = Duration.fromMillis(value as number).toISO();
                    break;
                }
            }
        }
        return result;
    }

    /**
     * Validate if all values conform to the field type.
     * @returns The validated field type or undefined
     */
    private validateType(value: CaseFilterFieldValue): FieldType | false {

        let result: FieldType | false = false;
        if (value !== undefined) {
            const v = !Array.isArray(value) ? [value] : value;
            switch (this.fieldDefinitionCopy.type) {
                case FieldType.BOOLEAN:
                    result = v.some(v => typeof v !== "boolean")
                        ? FieldType.BOOLEAN
                        : false;
                    break;

                case FieldType.DURATION:
                case FieldType.DATE:
                case FieldType.INTEGER:
                    result = !v.some(v => typeof v !== "number" || !Number.isInteger(v))
                        ? this.fieldDefinitionCopy.type as number as FieldType
                        : false;
                    break;

                case FieldType.FLOAT:
                    result = !v.some(v => typeof v !== "number" || !Number.isFinite(v))
                        ? FieldType.FLOAT
                        : false;
                    break;

                case FieldType.STRING: {
                    result = !v.some(v => typeof v !== "string")
                        ? FieldType.STRING
                        : false;
                    break;
                }

                default:
                    throw new Error(`Should not get here.`)
            }
        }
        return result;
    }


}
Decycler.registerSerializableType(CaseFilterField);
