import React from 'react';
import { useIntl } from 'react-intl';

import { useActor } from '@xstate/react';
import log from "./Logger.js";

import {
    Alignment,
    Button,
    Classes,
    Navbar,
    NavbarGroup,
} from '@blueprintjs/core';

import { ThemeProvider } from "styled-components";
import displayModeService from '../classes/displayModeService.js';
import socketService from '../classes/socketService.js';
import { DatasetSelectionDialog } from './DatasetSelectionDialog/DatasetSelectionDialog.js';
import Project from '@insight/common/interface/Project.js';
import { Activity } from './Base/Activity.js';

import { LanguagePopover } from './Base/language_popover/LanguagePopover.js';
import authProviderService from '../authentication/strategy/AuthProviderService.js';
import { BaseplateAuthStrategyComponentProvider } from './authentication/BaseplateAuthStrategyComponentProvider.js';
import { DataControllerContext, } from './DataProvider2.js';
import { WidgetControlContext } from './WidgetProvider.js';

export interface BaseplateProperties {
}

/* the following two variables will be set globally by WebPack */
declare var BUILD_DATE: string;
declare var VERSION: string;

export default function Baseplate(props: BaseplateProperties) {

    React.useEffect(()=>{
        log.debug(`*** Render Baseplate`);
    })

    const dataControl = React.useContext(DataControllerContext);
    const widgetControl = React.useContext(WidgetControlContext);

    const [drawerOpen, setDrawer] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [socketState] = useActor(socketService);

    const authStrategy = authProviderService.getAuthStrategy();
    const isAuthenticatedAzure = authStrategy.isAuthenticated()

    const toggleDrawer = () => {
        setDrawer(!drawerOpen);
    }

    const closeDrawer = () => {
        setDrawer(false);
    }

    const theme = {
        color: '#FFFFFF',
        disabledColor: '#909090',
        backGroundColor: "#242424",
        borderColor: '#D0D0D0',
    };

    const rtl = false;
    const intl = useIntl();


    const toggleDisplay = () => {
        displayModeService.send('TOGGLE');
    }

    const logout = () => {
        authStrategy.logout();
    }

    const login = () => {
        authStrategy.login();
    }

    const handleSelectDataset = (p: Project) => {
        setDialogOpen(false);
        if (dataControl !== undefined && widgetControl !== undefined) {
            widgetControl.overlay.setLabel("Loading ...");
            widgetControl.overlay.setIsOpen(true);
            dataControl.load(p, widgetControl.overlay.setProgress).then(() => {
                widgetControl.overlay.setIsOpen(false)
            })
        }
    }

    const version = VERSION.split('.').slice(0, 2).join('.');

    return (
        <ThemeProvider theme={theme}>
            {dialogOpen && <DatasetSelectionDialog
                onClose={() => setDialogOpen(false)}
                onSelectDataset={handleSelectDataset}
            />}
            <div id="app" className={`${rtl ? 'rtl' : ''}`}>
                <Navbar>
                    <NavbarGroup align={Alignment.LEFT}>
                        {/* <Button
                            className={Classes.MINIMAL}
                            icon="menu"
                            onClick={toggleDrawer}
                            disabled={!isAuthenticatedAzure}
                        /> */}
                        <Button
                            disabled={widgetControl === null || socketState.matches('disconnected') || !isAuthenticatedAzure}
                            className={Classes.MINIMAL}
                            onClick={() => setDialogOpen(true)}
                            icon="document-open"
                            text={intl.formatMessage({
                                defaultMessage: "Load Processes",
                                description: "Menu action label to load processes from server."
                            })}
                        />
                        <Button
                            className={Classes.MINIMAL}
                            onClick={toggleDisplay}
                            icon="modal"
                            text={intl.formatMessage({
                                defaultMessage: "Toggle single/dual view",
                                description: "Menu action label to toggle single/dual view.",
                            })}
                            disabled={!isAuthenticatedAzure}
                        />
                    </NavbarGroup>
                    <NavbarGroup className="bp5-align-center app-title">
                        InSight V{version}
                    </NavbarGroup>

                    <NavbarGroup align={Alignment.RIGHT}>
                        <Activity />
                        {isAuthenticatedAzure ? (
                            <Button
                                className={Classes.MINIMAL}
                                onClick={logout}
                                icon="log-out"
                                text={intl.formatMessage({
                                    defaultMessage: "Logout",
                                    description: "Menu action label to log out the user from the server."
                                })}
                            />
                        ) : (
                            <Button
                                className={Classes.MINIMAL}
                                onClick={login}
                                icon="log-in"
                                text={intl.formatMessage({
                                    defaultMessage: "Login",
                                    description: "Menu action label to log out the user from the server."
                                })}
                            />
                        )}
                        <LanguagePopover />
                    </NavbarGroup>
                </Navbar>
                <BaseplateAuthStrategyComponentProvider intl={intl} />
            </div>
            <span style={{ position: "fixed", right: "10px", bottom: "2px", fontSize: "60%" }}>Client-Version: {VERSION}, {BUILD_DATE}</span>
        </ThemeProvider>
    );

}
