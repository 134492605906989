import ChartDataStore, {
    isChartDataStore,
} from "@insight/common/interface/iChart.js";
import { sendRequest } from '../../classes/Request.js';

export const getChartData = (
    eventsfile: string,
    caseIds: number[],
    locale: string,
    options: { type?: string } = {}
): Promise<ChartDataStore | null> => {
    let url;
    const caseIdData: (number[] | number)[] = [];
    const query = new URLSearchParams({
        eventsfile,
    });

    switch (options.type) {
        case "RUNTIME_DIST":
            url = "/data/runtime?" + query.toString();
            break;

        default:
            url = "/data/chart?" + query.toString();
            break;
    }

    /**
     * convert list of case ids into a list of intervals and single case numbers to
     * shorten the amount of data needed to be transferred to the server. In the case
     * of thousands of cases, it would otherwise create a HTTP 413 error.
     */
    let interval: number[] = [];
    caseIds.forEach(caseId => {
        if (interval.length === 0) {
            interval.push(caseId);
        }
        else if (interval.length === 1) {
            if (caseId === interval[0] + 1) {
                interval.push(caseId);
            }
            else {
                caseIdData.push(interval[0]);
                interval[0] = caseId;
            }
        }
        else if (interval.length === 2) {
            if (caseId === interval[1] + 1) {
                interval[1] = caseId;
            }
            else {
                if (interval[1] === interval[0] + 1) {
                    caseIdData.push(interval[0]);
                    caseIdData.push(interval[1]);
                }
                else {
                    caseIdData.push(interval);
                }
                interval = [caseId];
            }
        }
    })
    if (interval.length === 1) {
        caseIdData.push(interval[0]);
    }
    else {
        caseIdData.push(interval);
    }

    return sendRequest(url, {
        method: "post",
        data: { caseIdData, options, locale },
    })
        .then((data) => {
            if (!isChartDataStore(data)) {
                // throw new Error("Expected a ChartDataStore structure.");
                return null;
            }
            else {
                return data;
            }
        });
};
