import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from '../config.js';

export type RequestModificator = <RequestDataType>(requestInit: AxiosRequestConfig<RequestDataType>) => void;
const requestModificators = new Map<string, RequestModificator>();

export type ResponseHandler = <ResponseDataType, RequestDataType = void>
    (res: AxiosResponse<ResponseDataType, RequestDataType>) =>
    Promise<AxiosResponse<ResponseDataType, RequestDataType>>;
const responseHandlers = new Map<string, ResponseHandler>([]);

// eslint-disable-next-line @typescript-eslint/no-empty-function
let activateSpinner: (active: boolean) => void = () => { };
export function initRequests(set: (active: boolean) => void) {
    activateSpinner = set;
}

let requests = 0;
let timeoutId: NodeJS.Timeout | null = null;
function setRequestActive() {
    requests++;
    if (requests === 1) {
        timeoutId = setTimeout(() => {
            activateSpinner(true)
        }, 100);
    }
}

function setRequestInactive() {
    requests--;
    if (requests === 0) {
        activateSpinner(false);
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
            timeoutId = null;
        }
    }
}

function doRequest<ResponseDataType, RequestDataType = never>(
    relativeURI: string,
    requestInit: AxiosRequestConfig<RequestDataType>) {
    const url = new URL(relativeURI, config.apiUrl + '/');
    setRequestActive();
    return axios<ResponseDataType,
        AxiosResponse<ResponseDataType, RequestDataType>,
        RequestDataType>(url.toString(), requestInit)
        .then(res => {
            setRequestInactive();
            return executeResponseHandlers<ResponseDataType, RequestDataType>(res)
        })
        .catch(err => {
            setRequestInactive();
            throw err;
        })
}

/**
 * Send an object's properties to an URI as JSON.
 *
 * @param relativeURI The URI relative to the configured base URI
 * @param object The JSON-serializable object
 * @param requestInit The list of HTTP options. METHOD and Content-Type header are immutable
 * @returns promise to the response
 */
export function sendJSONRequest<ResponseDataType, RequestDataType>(
    relativeURI: string,
    object: RequestDataType,
    requestInit: AxiosRequestConfig<RequestDataType> = { headers: {} }
) {
    requestInit.headers = requestInit.headers ? requestInit.headers : {};
    executeRequestModificators<RequestDataType>(requestInit);
    requestInit = {
        method: "POST",
        responseType: "json",
        ...requestInit,
        headers: {
            ...requestInit.headers,
            // "Content-Type": "application/json",
        },
        data: object,
    };
    return doRequest<ResponseDataType, RequestDataType>(relativeURI, requestInit);
}

/**
 * Send a request to an URI.
 * @param relativeURI The URI relative to the configured base URI
 * @param requestInit The list of HTTP options. METHOD and Content-Type header are immutable
 * @returns A promise to the response
 */
export async function sendRequest<ResponseDataType, RequestDataType>(
    relativeURI: string,
    requestInit: AxiosRequestConfig<RequestDataType> = { headers: {} }
) {
    requestInit.headers = requestInit.headers ? requestInit.headers : {};
    requestInit = {
        method: "GET",
        ...requestInit,
        headers: { ...requestInit.headers },
    };
    await executeRequestModificators(requestInit);
    return doRequest<ResponseDataType, RequestDataType>(relativeURI, requestInit).then(res => res.data);
}

export function setRequestModificator(name: string, modificator: RequestModificator) {
    if (!requestModificators.has(name)) {
        requestModificators.set(name, modificator);
    } else {
        throw new Error(`requestModificator '${name}' already set.`);
    }
}

export function removeRequestModificator(name: string) {
    requestModificators.delete(name);
}

async function executeRequestModificators<RequestDataType>(requestOptions: AxiosRequestConfig<RequestDataType>) {
    for (const [key, value] of requestModificators) {
        await value(requestOptions)
    }
}

export function setResponseHandler(name: string, handler: ResponseHandler) {
    if (!responseHandlers.has(name)) {
        responseHandlers.set(name, handler);
    } else {
        throw new Error(`responseHandler '${name}' already set.`);
    }
}

export function removeResponseHandler(name: string) {
    responseHandlers.delete(name);
}

function executeResponseHandlers<ResponseDataType, RequestDataType>
    (res: AxiosResponse<ResponseDataType, RequestDataType>):
    Promise<AxiosResponse<ResponseDataType, RequestDataType>> {
    const iterator = responseHandlers.values();
    return (function loop(res): Promise<AxiosResponse<ResponseDataType, RequestDataType>> {
        const { value: handler, done }: IteratorResult<ResponseHandler> = iterator.next();
        if (!done) {
            return handler(res).then(loop);
        } else {
            return Promise.resolve(res)
        }
    })(res);
}
