export type GraphFilterId = string;
export type GraphFilterSettings = unknown;

/**
 * The graph filter components profile is determined by two callbacks to acutally
 * activate the filter (setFilter) and to get the filter settings.
 */
export type GraphFilterProfile = {
    /**
     * @param filterData The data (structure & contenent unknown to the interaction controller)
     * to set this filter. Set to default, if unknowns
     */
    setFilter: (filterData: GraphFilterSettings) => void;
    // getFilterSettings: () => GraphFilterSettings;
}

export class MultiGraphFilterSettings extends Map<GraphFilterId, GraphFilterSettings> {
    copy() {
        const result = new MultiGraphFilterSettings();
        for (const [key, val] of this) {
            if (val === Object(val)) {
                result.set(key, Object.assign({}, val)) // copy object
            }
            else {
                result.set(key, val); // primitives are copied
            }
        }
        return result;
    }
}

/**
 * The only GRAPH FILTERS currently are the edge and node sliders, which
 * filter the view detail of the graph. Graph filters work on the level
 * of the created process graph. They are to be differentiated from SELECTORS
 * which selected cases to build the graph.
 */
export class GraphFilters extends Map<GraphFilterId, GraphFilterProfile> {
    // getSettings() {
    //     const result = new FilterSettings();
    //     for (const [id, profile] of this) {
    //         result.set(id, profile.getFilterSettings());
    //     }
    //     return result;
    // }
    setSettings(settings: MultiGraphFilterSettings | undefined) {
        for (const [id, profile] of this) {
            const setting = settings?.get(id);
            profile.setFilter(setting);
        }
    }
    
}

