import { NonFunctionProperties } from "../@types/utilityTypes.js";
import {Decycler} from "../decycler/Decycler.js";
import stringBreak from "../stringbreak/stringbreak.js";

export type OrgProps = {
    [key: string]: string | number | boolean
}

export type OrgAttributes = {
    name: string;
    description?: string;
    props: OrgProps
}

export type OrgData = OrgAttributes & {
    parent: OrgData | string;
    children: OrgData[];
}
export type OrgDataNonRecursive = Omit<OrgData, "parent" | "children"> & { children: OrgDataNonRecursive[] }

export type Org = NonFunctionProperties<OrgStructure>

export class OrgStructure implements OrgAttributes {
    static nextId = 1;
    id: number;
    name: string;
    description?: string;
    props: OrgProps;

    parent: OrgStructure | null;
    level: number;
    children: Set<OrgStructure>;

    static fromData(orgData: OrgDataNonRecursive) {
        const orgStructure = new OrgStructure(orgData.name);
        orgStructure.description = orgData.description;
        orgStructure.props = orgData.props;
        for (const childData of orgData.children) {
            const child = OrgStructure.fromData(childData)
            orgStructure.addChild(child)
        }
        return orgStructure;
    }

    static fromArray(orgs: OrgData[]) {
        const result: OrgStructure[] = [];
        const topOrgs = orgs.filter(org => org.parent === "");
        for (const topOrg of topOrgs) {
            result.push(OrgStructure.fromData(topOrg));
        }
        return result;
    }

    static fromJSON(s: string) {
        const obj = JSON.parse(s);
        return new Decycler().retrocycle(obj);
    }

    constructor(name: string, description?: string) {
        this.id = OrgStructure.nextId++;
        this.name = name;
        this.props = {};
        this.description = description
        this.parent = null;
        this.level = 0;
        this.children = new Set();
    }

    private setLevel(level: number) {
        this.level = level;
        if (this.children.size > 0) {
            for (const child of this.children.values()) {
                child.setLevel(level + 1);
            }
        }
    }

    setParent(parent: OrgStructure) {
        this.parent = parent;
        this.setLevel(parent.level + 1);

    }

    removeParent() {
        const parent = this.parent;
        this.parent = null;
        this.setLevel(0);
        if (parent !== null) {
            parent.removeChild(this);
        }
        return parent;
    }

    addChild(child: OrgStructure) {
        if (child.parent) {
            child.removeParent();
        }
        child.parent = this;
        child.setLevel(this.level + 1);
        this.children.add(child);
    }

    removeChild(child: OrgStructure) {
        let result: OrgStructure | null = null;
        if (this.children.has(child)) {
            this.children.delete(child)
            child.removeParent()
            result = child;
        }
        return result;
    }

    findOrg(name: string): OrgStructure | undefined {
        if (this.name === name) return this;
        for (const child of this.children) {
            const found = child.findOrg(name);
            if (found) return found
        }
        return undefined;
    }

    findOrgsById(ids: number[]): OrgStructure[] {
        const result = [];
        if (ids.includes(this.id)) result.push(this);
        for (const child of this.children) {
            result.push(...child.findOrgsById(ids));
        }
        return result;
    }

    getAncestor(level: number): OrgStructure | undefined {
        if (level === 0) {
            return this;
        }
        else {
            return this.parent?.getAncestor(level - 1);
        }
    }

    getAncestors(): OrgStructure[] {
        const result: OrgStructure[] = [this];
        let p = this.parent;
        while (p !== null) {
            result.push(p);
            p = p.parent;
        }
        return result;
    }

    /**
     * Gets descendants of org structure for the number of levels given.
     * Top down, if level > 0, bottom up, if level < 0.
     *
     * @param level
     * @returns
     */
    getDescendants(level: number = Infinity): OrgStructure[] {
        let result: OrgStructure[] = [];
        if (level > 0) {
            for (const child of this.children) {
                result.push(child);
                result.push(...child.getDescendants(level - 1));
            }
        }
        else if (level < 0) {
            result = Array.from(this.getLeafs().reduce<Set<OrgStructure>>((set, unit) => {
                for (let lvl = -1; lvl >= level; lvl -= 1) {
                    const ancestor = unit.getAncestor(-lvl)
                    if (ancestor !== undefined) set.add(ancestor)
                }
                return set;
            }, new Set<OrgStructure>()))
        }
        return result;
    }

    getLeafs(): OrgStructure[] {
        if (this.children.size > 0) {
            return Array.from(this.children).reduce<OrgStructure[]>((a, c) => {
                a = a.concat(c.getLeafs())
                return a;
            }, [])
        }
        else {
            return [this];
        }
    }

    /**
     * Builds a map of org structure leaf names to the names of
     * their ancestors on a certain level from top of the
     * passed org units names.
     * @param targets names of root org units
     * @param level requsted level of sub-units
     * @returns A map of leaf names to sub-unit names
     */
    getLeafsToDescendantsMap(targets: string[], level: number) {
        const map: { name: string, parent: string }[] = [];
        for (const targetName of targets) {
            const targetOrg = this.findOrg(targetName);
            if (targetOrg !== undefined) {
                const subTargets = targetOrg.getDescendants(level);
                for (const subTarget of subTargets) {
                    for (const leaf of subTarget.getLeafs()) {
                        map.push({ name: leaf.name, parent: subTarget.name })
                    }
                }
            }
        }
        return map;
    }

    toData(): OrgDataNonRecursive {
        const org: OrgDataNonRecursive = {
            name: this.name,
            description: this.description,
            props: this.props,
            children: Array.from(this.children).map(child => child.toData()).sort((c1, c2) => c1.name.localeCompare(c2.name))
        }
        return org;
    }

    toDot(): string {
        const nodes = this.getDescendants();
        nodes.push(this);
        const lines: string[] = [`rankdir="LR"`];
        lines.push(...nodes.map((n, i) => {
            return `${i} [label="${stringBreak(n.name, 15)}"]`
        }));
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            lines.push(...Array.from(node.children).map(c => {
                const j: number = nodes.findIndex(n => n === c);
                return `${i} -> ${j}`
            }))
        }
        const dot = `digraph G {\n${lines.join(";\n")}}\n`
        return dot;
    }

    toJSON(): string {
        return JSON.stringify(new Decycler().decycle(this))
    }

}
Decycler.registerSerializableType(OrgStructure);
