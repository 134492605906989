import { Duration, DateTime, DurationUnit, DateTimeUnit } from "luxon";

const MS_in_SEC = 1000;
const MS_in_MIN = MS_in_SEC * 60;
const MS_in_HOUR = MS_in_MIN * 60;
const MS_in_DAY = MS_in_HOUR * 24;
const MS_in_WEEK = MS_in_DAY * 7;
const MS_in_MONTH = MS_in_DAY * 365 / 21;
const MS_in_YEAR = MS_in_DAY * 365;

export const SHORT_UNITS = ["ms", "s", "mi", "h", "d", "w", "mo", "y"] as const;
export type SHORT_UNIT = typeof SHORT_UNITS[number];

abstract class TimeUnit {
    protected static units: Record<string, TimeUnit> = {};
    static _sortedTimeUnits: TimeUnit[] | undefined = undefined;

    unitLabel: SHORT_UNIT;
    abstract unit_in_ms: number;
    abstract unitPlural: DurationUnit;
    abstract unitSingular: DateTimeUnit;
    abstract formatTimestamp: (d: number, locale: string) => string;
    abstract maxUnit: number;
    abstract unitSteps: number[];

    static get sortedTimeUnits() {
        if (this._sortedTimeUnits === undefined) {
            this._sortedTimeUnits = Object.values(this.units).sort((u1,u2)=>u1.unit_in_ms - u2.unit_in_ms)
        }
        return this._sortedTimeUnits
    }

    static determineAxisUnitAndIntervalSize(axisTimeSpan: number, maxIntervals: number): { timeUnit: TimeUnit, intervalSize: number } {
        let intervalSize: number | undefined = undefined
        const timeUnit: TimeUnit | undefined = TimeUnit.sortedTimeUnits.find(timeUnit => {
            if (axisTimeSpan < timeUnit.maxUnit * timeUnit.unit_in_ms) {
                intervalSize = timeUnit.unitSteps.find(step => {
                    const intervals = axisTimeSpan / (step * timeUnit.unit_in_ms);
                    const result = intervals <= maxIntervals
                    return result;
                });
                if (intervalSize) {
                    intervalSize *= timeUnit.unit_in_ms;
                    return true;
                }
            }
            return false;
        })
        if (timeUnit && intervalSize)
            return { timeUnit, intervalSize }
        else
            return { timeUnit: TimeUnit.sortedTimeUnits[0], intervalSize: 1 }
    }

    constructor(unitLabel: SHORT_UNIT) {
        this.unitLabel = unitLabel;
        TimeUnit.units[unitLabel] = this;
    }

    // abstract durationLabel(intl: IntlShape): string

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUnits(minTime: number, maxTime: number): number {
        return Duration.fromMillis(maxTime - minTime).as("years") / 10;
    }

    /**
     * @param timeStamp time in ms
     * @returns the time of the time unit belonging to the timestamp, e.g. 00:00 of the first day of the month, if month is the unit.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTimeUnit(timeStamp: number): number {
        return DateTime.fromMillis(timeStamp).startOf(this.unitSingular).toMillis()
    }

    formatDuration = (d: number, locale: string) => {
        return Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(Math.floor(d / this.unit_in_ms))
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getDuration(ms: number) {
        return ms / this.unit_in_ms
    }

}

/**
 * A time  class for seconds time intervals
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class MilliSecond extends TimeUnit {
    unit_in_ms = 1;
    unitPlural: DurationUnit = "milliseconds"
    unitSingular: DateTimeUnit = "millisecond";

    constructor() { super("ms") }

    formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            fractionalSecondDigits: 3,
            hour12: false,
        }
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formatDuration = (d: number, locale: string) => {
        return d.toString();
    }

    maxUnit = 5000;
    unitSteps = [1, 2, 5, 10, 50, 100, 200, 500]
}

/**
 * A time  class for seconds time intervals
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Second extends TimeUnit {
    unit_in_ms = MS_in_SEC;
    unitPlural: DurationUnit = "seconds"
    unitSingular: DateTimeUnit = "second";

    constructor() { super("s") }

    formatTimestamp = (t: number, locale: string) => {
        let options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
        }

        options = (t % this.unit_in_ms !== 0)
            ? { ...options, fractionalSecondDigits: 3 }
            : { ...options }
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    formatDuration = (d: number, locale: string) => {
        return Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.floor(d / this.unit_in_ms))
    }

    maxUnit = 180;
    unitSteps = [0.25, 0.5, 1, 2, 5, 10, 15, 20, 30]
}

/**
 * A time  class for minutes time intervals
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Minute extends TimeUnit {
    unit_in_ms = MS_in_MIN
    unitPlural: DurationUnit = "minutes"
    unitSingular: DateTimeUnit = "minute";

    constructor() { super("mi") }

    formatTimestamp = (t: number, locale: string) => {
        let options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        }

        options = (t % this.unit_in_ms !== 0)
            ? { ...options, second: "numeric", hour12: false }
            : options
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    formatDuration = (d: number, locale: string) => {
        d = d - d % this.unit_in_ms
        const secs = d % this.unit_in_ms;
        const mins = (d - secs) / this.unit_in_ms;
        let result = Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(mins)
        if (secs !== 0) {
            result += Duration.fromMillis(secs).toFormat(":ss")
        }
        return result
    }

    maxUnit = 180;
    unitSteps = [0.25, 0.5, 1, 2, 5, 10, 15, 20, 30]
}

/**
 * A time  class for hours time intervals
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Hour extends TimeUnit {
    unit_in_ms = MS_in_HOUR
    unitPlural: DurationUnit = "hours"
    unitSingular: DateTimeUnit = "hour";

    constructor() { super("h") }

    formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        }

        return Intl.DateTimeFormat(locale, options).format(t);
    }

    formatDuration = (d: number, locale: string) => {
        const mins = d % this.unit_in_ms;
        const hrs = (d - mins) / this.unit_in_ms;
        let result = Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(hrs)
        if (mins !== 0) {
            result += Duration.fromMillis(mins).toFormat(":mm")
        }
        return result
    }
    maxUnit = 48;
    unitSteps = [0.25, 0.5, 1, 2, 3, 6]
}

/**
 * A time  class for days time intervals
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Day extends TimeUnit {
    unit_in_ms = MS_in_DAY;
    unitPlural: DurationUnit = "days"
    unitSingular: DateTimeUnit = "day";

    constructor() { super("d") }

    formatTimestamp = (t: number, locale: string) => {
        // t = t - t % MS_in_HOUR
        const timeInDay = (t - new Date().getTimezoneOffset()*60*1000) % MS_in_DAY;
        let options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        }

        if (timeInDay !== 0) {
            options = {
                ...options,
                hour: "numeric",
                minute: "numeric",
                hour12: false,
            }
        }

        return Intl.DateTimeFormat(locale, options).format(t);
    }

    formatDuration = (d: number, locale: string) => {
        const hrs = d % this.unit_in_ms;
        const days = (d - hrs) / this.unit_in_ms;
        let result = Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(days)
        if (hrs !== 0) {
            result += Duration.fromMillis(hrs).toFormat(":hh:mm")
        }
        return result
    }

    maxUnit = 90;
    unitSteps = [0.25, 0.5, 1, 7]
}

/**
 * A time  class for weeks time intervals
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Week extends TimeUnit {
    unit_in_ms = 7 * MS_in_WEEK;
    unitPlural: DurationUnit = "weeks"
    unitSingular: DateTimeUnit = "week";

    constructor() { super("w") }

    formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
        }
        return DateTime.fromMillis(t).weekNumber.toString() + "-" + Intl.DateTimeFormat(locale, options).format(t);
    }

    maxUnit = 52
    unitSteps = [1, 2, 4];
}

/**
 * A time  class for months time intervals
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Month extends TimeUnit {
    unit_in_ms = MS_in_MONTH;
    unitPlural: DurationUnit = "months"
    unitSingular: DateTimeUnit = "month";

    constructor() { super("mo") }

    formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            month: "numeric",
            year: "numeric",
        }
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    maxUnit = 48
    unitSteps = [1, 2];
}

/**
 * A time  class for months time intervals
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Year extends TimeUnit {
    unit_in_ms = MS_in_YEAR
    unitPlural: DurationUnit = "years"
    unitSingular: DateTimeUnit = "year";

    constructor() { super("y") }

    formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
        }
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    maxUnit = 50
    unitSteps = [1, 2, 5, 10];
}
new MilliSecond();
new Second();
new Minute();
new Hour();
new Day();
new Week();
new Month();
new Year();
export default TimeUnit;
