import React from 'react';
import { useActor } from "@xstate/react";
import { Grid } from './Grid/Grid.js';
import { View } from './View/View.js';
import displayModeService from '../classes/displayModeService.js';
import { DataContext } from './DataProvider2.js';
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary.js';

export const Homepage: React.FC = () => {

    React.useEffect(() => {
        console.log(`*** Render Homepage`);
    })

    const [displayMode] = useActor(displayModeService);
    const single = displayMode.matches("single");
    const components = [];

    const dataContext = React.useContext(DataContext);

    if (dataContext !== undefined) {
        if (single) {
            components.push(
                <div id="desk" key={components.length + 1}>
                    <Grid columns={5} rows={10}>
                        <View
                            id="view_1"
                            top={1}
                            left={1}
                            bottom={10}
                            right={5}
                            key="1"
                            dataProvisionContext={dataContext}
                        />
                    </Grid>
                </div>
            )
        }
        else {
            components.push(
                <div id="desk" key={components.length + 1}>
                    <Grid columns={10} rows={10}>
                        <View
                            id="view_1"
                            top={1}
                            left={1}
                            bottom={10}
                            right={5}
                            key="1"
                            dataProvisionContext={dataContext}
                        />
                        <View
                            id="view_2"
                            top={1}
                            left={6}
                            bottom={10}
                            right={10}
                            key="2"
                            dataProvisionContext={dataContext}
                        />
                    </Grid>
                </div>
            )
        }
    }

    return (<ErrorBoundary>
        {components}
    </ErrorBoundary>
    )
}