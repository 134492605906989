import { Decycler } from '@insight/common/decycler/Decycler.js';
import DataController from './DataController.js';
import {Variant} from "@insight/common/interface/iVariant.js";

/**
 * Reminder: the class name determines the index into the array of filenames in the DataProvider class
 * which are requested first.
 */
class VariantsController extends DataController<Variant[]> {
    constructor() {
        super([]);
    }
}
Decycler.registerSerializableType(VariantsController);
export default VariantsController;