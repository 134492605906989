import { Icon } from '@blueprintjs/core';
import React from 'react';
import { sendRequest } from '../Request.js';
import { CaseFilterFieldDefinition, FieldType } from './CaseFilterFieldDefinition.js';
import { CaseFilter } from './CaseFilter.js';
import { Decycler }  from '@insight/common/decycler/Decycler.js';
import { CaseFilterType } from './CaseFilterType.js';
import { IntlShape } from 'react-intl';
import { Duration } from "luxon";

export class CaseFilterRunTime extends CaseFilter {
    execute(eventsfile: string) {
        const query = new URLSearchParams({
            eventsfile,
        });
        return sendRequest<number[], { [key: string]: unknown }>("runtime_filter?" + query.toString(), {
            method: "post",
            data: this.fieldValues(),
        })
    }

    label(intl: IntlShape) {
        let msg: string;
        const min = Duration.fromMillis(this.fields.min.value as number).toISO();
        const max = Duration.fromMillis(this.fields.max.value as number).toISO();
        const values = {min,max}
        if (this.fields.min && this.fields.max) {
            msg = intl.formatMessage({
                id: "this.msg.event_count",
                defaultMessage: "{min} ≤ # events ≤ {max}",
                description: "Describe boundaries of EventCount this.",
            }, values) as string;
        }
        else if (this.fields.min) {
            msg = intl.formatMessage({
                id: "this.msg.event_count.min_only",
                defaultMessage: "# events ≥ {min}",
                description: "Describe min bounday of EventCount this.",
            }, values) as string;
        }
        else if (this.fields.max) {
            msg = intl.formatMessage({
                id: "this.msg.event_count.max_only",
                defaultMessage: "# events ≤ {max}",
                description: "Describe max boundary of EventCount this.",
            }, values) as string;
        }
        else {
            msg = intl.formatMessage({
                id: "this.msg.event_count.missing_parameters",
                defaultMessage: "Filter needs min and/ or max parameters",
                description: "Describe missing paarameters EventCount this.",
            })
        }
        return msg;
    }
}
Decycler.registerSerializableType(CaseFilterRunTime);

new CaseFilterType({
    id: "runtime",
    name: intl => intl.formatMessage({
        id: "filter.def.runtime.name",
        defaultMessage: "Runtime",
        description: "Name of runtime filter.",
    }),
    description: intl => intl.formatMessage({
        id: "filter.def.runtime.description",
        defaultMessage: "Limit cases to runtime",
        description: "Description of runtime filter.",
    }),
    childrenAllowed: false,
    fieldDefinitions: [
        new CaseFilterFieldDefinition({
            id: "min",
            name: intl => intl.formatMessage({
                id: "filter.def.runtime.parameter.min.name",
                defaultMessage: 'Min. runtime',
                description: "Name of min parameter for runtime filter.",
            }),
            required: true,
            visible: true,
            type: FieldType.DURATION,
            description: intl => intl.formatMessage({
                id: "filter.def.runtime.parameter.min.description",
                defaultMessage: 'The minimum run time of a case. 0 if empty.',
                description: "Description of min parameter for runtime filter.",
            }),
            placeholder: intl => intl.formatMessage({
                id: "filter.def.runtime.parameter.min.placeholder",
                defaultMessage: "d hh:mm[:ss][:ms]",
                description: 'Placeholder for min. parameter (format)',
            }),
            vector: false
        }),
        new CaseFilterFieldDefinition({
            id: "max",
            name: intl => intl.formatMessage({
                id: "filter.def.runtime.parameter.max.name",
                defaultMessage: 'Max. runtime',
                description: "Name of max parameter for runtime filter.",
            }),
            required: true,
            visible: true,
            type: FieldType.DURATION,
            description: intl => intl.formatMessage({
                id: "filter.def.runtime.parameter.max.description",
                defaultMessage: 'The maximal runtime to qualify a case. Unlimited if empty.',
                description: "Description of max parameter for runtime filter.",
            }),
            placeholder: intl => intl.formatMessage({
                id: "filter.def.runtime.parameter.max.placeholder",
                defaultMessage: "d hh:mm[:ss][:ms]",
                description: 'Placeholder for max. parameter (format)',
            }),
            vector: false,
        }),
    ],
    icon: < Icon icon="time" />,
    class: CaseFilterRunTime
})

