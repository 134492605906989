/**
 *
 */
import * as CryptoJS from 'crypto-js';

export type VersionInfo = Array<{
    className: string,
    md5: string;
}>


export class TypeWithVersionInfo {
    constructors: VersionInfo = [];

    // eslint-disable-next-line @typescript-eslint/ban-types
    type: Function;

    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(type: Function) {
        this.type = type;
        /**
         * set name of class explicitly so any obfuscator / uglifier does not destroy it,
         * This is needed for the decyler / recycler in order to rebuild objects of that class.
         */
        Object.defineProperty(type, "name", { writable: true, value: type.name });

        /** store the "versions" of all constructors by doing an md5 over their source */
        do {
            const md5 = CryptoJS.MD5(type.toString()).toString(CryptoJS.enc.Hex);
            this.constructors.push({ className: type.name, md5 })
            type = type.prototype;
        } while (type);
    }

    compare(vc: VersionInfo) {
        return this.constructors.length === vc.length &&
            this.constructors.every((c, i) => {
                const result = c.className === vc[i].className && c.md5 === vc[i].md5;
                if (result === false) {
                    let errMsg = "";
                    if (c.className !== vc[i].className) {
                        errMsg += `Constructor ${i}, ${c.className} does not match with ${vc[i].className} `
                    }
                    if (c.md5 !== vc[i].md5) {
                        errMsg += `Constructor ${i}, ${c.className}, changed version.`
                    }
                    console.log(errMsg);
                }
                return result;
            })
    }

    toJSON(): VersionInfo {
        return this.constructors;
    }
}
