import { MultiGraphFilterSettings } from "./GraphFilter.js";
import { MultiDimSelection } from "./GraphInteractionController.js";


/**
 * A HistoryItem consists if (possibly) multiple combined selections
 * and multiple graph filter settings.
 */
export type Historyitem = {
    selections: MultiDimSelection;
    graphFilters: MultiGraphFilterSettings;
}

const HISTORY_STACK_SIZE = 8;

export class SelectionHistory {
    historyPos = -1;
    history: Historyitem[] = [];

    static createItem(): Historyitem {
        return {
            selections: new MultiDimSelection(),
            graphFilters: new MultiGraphFilterSettings()
        }
    }

    static copyItem(item: Historyitem): Historyitem {
        return {
            selections: item.selections.copy(),
            graphFilters: item.graphFilters.copy(),
        }
    }

    clear() {
        this.historyPos = -1;
        this.history = [];
    }

    clearAfter(item: Historyitem | undefined) {
        if (item !== undefined) {
            const ptr = this.findIndex((i) => i === item);
            if (ptr !== undefined) {
                this.history.splice(ptr + 1);
                this.historyPos = ptr;
            }
        }
        else {
            this.clear();
        }
    }

    /**
     * Finds the first history item matching for which
     * the callback returns true. Starts at the youngest
     * item in the history.
     * @param cb 
     * @returns 
     */
    find(cb: (item: Historyitem) => boolean) {
        const ptr = this.findIndex(cb);
        return ptr >= 0 ? this.history[ptr] : undefined;
    }

    private findIndex(cb: (item: Historyitem) => boolean) {
        let ptr = this.historyPos;
        while (ptr >= 0 && !cb(this.history[ptr])) ptr--;
        return ptr;
    }

    filter(cb: (item: Historyitem, idx?: number) => boolean) {
        return this.history.filter(cb);
    }

    get length() {
        return this.history.length;
    }

    append(item: Historyitem) {
        /* init stack or push item on stack */
        if (this.historyPos === -1) {
            /* nothing on stack, init it */
            this.history = [item];
            this.historyPos = 0;
        } else {
            if (this.historyPos === this.history.length - 1) {
                /* at end of stack, limit size */
                if (this.history.length >= HISTORY_STACK_SIZE) {
                    this.history.shift();
                }
            } else {
                /* somewhere in stack, remove old states above the pointer */
                this.history = this.history.slice(0, this.historyPos + 1);
            }
            this.history.push(item);
            this.historyPos = this.history.length - 1;
        }
    }

    removeCurrent() {
        if (this.historyPos >= 0) {
            this.history.splice(this.historyPos);
            this.historyPos--;
        }
    }

    goBack(cb: (currentItem: Historyitem | undefined, previousItem: Historyitem) => void) {
        if (this.historyPos >= 0) {
            const previousStackItem = this.history[this.historyPos];
            this.historyPos--;
            const stackItem = this.historyPos >= 0 ? this.history[this.historyPos] : undefined;
            cb(stackItem, previousStackItem);
        }
        else if (this.historyPos === 0) {
            this.historyPos--;
        }
    }

    goFwd(cb: (currentItem: Historyitem, previousItem: Historyitem | undefined) => void) {
        if (this.historyPos < this.history.length - 1) {
            const previousStackItem = this.historyPos >= 0 ? this.history[this.historyPos] : undefined;
            this.historyPos++;
            const stackItem = this.history[this.historyPos];
            cb(stackItem, previousStackItem);
        }
    }

    get backwardPossible() {
        return this.historyPos >= 0;
    }

    get forwardPossible() {
        return this.historyPos < this.history.length - 1;
    }
    
}

