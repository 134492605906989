import { GraphFilterId, GraphFilterProfile, GraphFilters, GraphFilterSettings } from "./GraphFilter.js";
import { SelectionHistory, Historyitem } from "./SelectionStack.js";

type SelectorId = string;

/**
 * Each selection can combine multiple selector dimensions. However, each dimension can only
 * be represented once in any selection.
 */
export type SelectorDimension = typeof selectorDimensions[number];
const selectorDimensions = ["org", "process", "runtime", "start_time_span", "cover_time_span"] as const;

/**
 * The selectorKind differentiates selectors of being set in the process graph or in some 
 * other representation of data (e.g. the time charts) or being set in an external
 * components, which visualizes the selection.
 */
const selectorKind = ["in-graph", "ex-graph"] as const;
export type SelectorKind = typeof selectorKind[number];

/**
 * The data representing a case selector. Any selection on the
 * history stack containing a selection made by an in-graph selector
 * will be popped of the stack before a new non-local case selection is
 * pushed on the stack.
 */
type SelectorProfile = {
    /** an id of the selector dimension */
    dimension: SelectorDimension;
    kind: SelectorKind
    setState: (stateData: unknown) => void;
}

/**
 * A collection of case selectors, accessible by their id
 */
class Selectors extends Map<SelectorId, SelectorProfile> { }

/**
 * The selection made by a single case selector. It contains
 * the id of the selector, the selected cases and the data representing
 * the state of the selector after it made the selection.
 */
type SingleDimSelection = {
    /** the case selection component which made the selection */
    selectorId: SelectorId;
    /** the selected case ids or undefined if the selector was deactivated */
    caseIds: number[] | undefined;
    /** the data representing the state of the case selection component */
    selectorState: unknown;
}

export type StackState = { totalItems: number, historyPos: number, localItems: number };

function selectionsEqual(s1: SingleDimSelection | undefined, s2: SingleDimSelection | undefined) {
    const result = s1 !== undefined
        && s2 !== undefined
        && s1.selectorId === s2.selectorId
        && (
            (s1.caseIds === undefined && s2.caseIds === undefined)
            || (s1.caseIds !== undefined
                && s2.caseIds !== undefined
                && s1.caseIds.length === s2.caseIds.length
                && s1.caseIds.every(caseId => s2.caseIds!.includes(caseId))
            )
        )
    return result;
}

/**
 * A case selection represents the state and the selected cases of possibly
 * multiple case selectors, i.e. ValidatedSingleDimSelection class objects,
 * of different dimensions.
 */
export class MultiDimSelection extends Map<SelectorDimension, SingleDimSelection> {
    copy() {
        const result = new MultiDimSelection();
        for (const [key, val] of this) {
            result.set(key, { ...val });
        }
        return result;
    }
}


export class GraphInteractionController {

    selectors: Selectors = new Selectors();
    graphFilters: GraphFilters = new GraphFilters();
    doSelect: (selection: MultiDimSelection | undefined) => void;
    history: SelectionHistory = new SelectionHistory();

    constructor(doSelectCallback: (selection: MultiDimSelection | undefined) => void) {
        this.doSelect = doSelectCallback;
    }

    registerSelector(selectorId: SelectorId, selectorProfile: SelectorProfile) {
        this.selectors.set(selectorId, selectorProfile);
    }

    registerGraphFilter(filterId: GraphFilterId, filterProfile: GraphFilterProfile) {
        this.graphFilters.set(filterId, filterProfile);
    }

    /**
     * Copies the current history item or creates a new one, if no item is in history.
     * Stores the graph filter in the item, appends the history item to the history
     * and activates the graph filter.
     * @param filterId 
     * @param settings 
     */
    setGraphFilter(filterId: GraphFilterId, settings: GraphFilterSettings) {
        const tmp = this.history.find(() => true); // get topmost history item
        const historyItem = tmp ? SelectionHistory.copyItem(tmp) : SelectionHistory.createItem();
        const graphFilterProfile = this.graphFilters.get(filterId)
        if (graphFilterProfile) {
            historyItem.graphFilters.set(filterId, settings); // store the graphfilter in the history item
            this.history.append(historyItem); // append the item to the history
            graphFilterProfile.setFilter(settings); // and set the graph filter
        }
    }

    clearHistory() {
        this.switchSelectorsAndSelect(undefined, this.history.find(() => true)?.selections);
        this.graphFilters.setSettings(undefined);
        this.history.clear();
    }

    /**
     * Perform the case selection demanded by one of the selection components
     * @param selection // selection information passed by the selector component
     */
    select(selection: SingleDimSelection) {
        const selector = this.selectors.get(selection.selectorId);
        if (selector) {

            // get current history item
            const activeHistoryItem = this.history.find(() => true); // gets topmost history item

            // go back in history and find first selection of same kind
            const nextHistoryItemOfKind = this.history.find(
                selector.kind === "in-graph"
                    ? (item) => true // simply get current item of history
                    : (item) => !this.hasInGraphSelection(item.selections)
            );

            /**
             * maybe ACTIVATE a selection
             */
            this.history.clearAfter(nextHistoryItemOfKind); // clears stack if last history item is undefined

            const activeSelection = activeHistoryItem?.selections.get(selector.dimension);
            const nextSelectionOfKind = nextHistoryItemOfKind?.selections.get(selector.dimension);

            // if the active selection is different from new selection, the new selection needs
            // at least to be activated
            let needActivation = !selectionsEqual(selection, activeSelection);

            // if the next selection of the same kind in the history is different
            // from the new selection, a new selection needs to be created and appended
            // to the history
            const needNewSelection = !selectionsEqual(selection, nextSelectionOfKind);

            // append new item to history if needed, copy existing of kind if not undefined
            if (needNewSelection) {
                let newItem = SelectionHistory.createItem();
                if (nextHistoryItemOfKind !== undefined) {
                    newItem = SelectionHistory.copyItem(nextHistoryItemOfKind) // copy to keep other dimensions selections && graph filter
                }
                if (Array.isArray(selection.caseIds)) {
                    newItem.selections.set(selector.dimension, selection) // select along dimension of selector
                }
                else {
                    newItem.selections.delete(selector.dimension);
                }
                this.history.append(newItem);
                needActivation = true;
            }

            // activate selection if needed
            if (needActivation) {
                const currentHistoryItem = this.history.find(() => true)
                this.switchSelectorsAndSelect(currentHistoryItem?.selections, activeHistoryItem?.selections, selection.selectorId);
                this.graphFilters.setSettings(currentHistoryItem?.graphFilters)
            }
        }
        else {
            throw new Error(`Unregistered selector id ${selection.selectorId}. Only ${Array.from(this.selectors.keys()).join(", ")} are registered.`)
        }
    }


    get backwardPossible() {
        return this.history.backwardPossible
    }

    goBack() {
        this.history.goBack((stackItem: Historyitem | undefined, previousStackItem: Historyitem) => {
            this.switchSelectorsAndSelect(stackItem?.selections, previousStackItem.selections);
            this.graphFilters.setSettings(stackItem?.graphFilters)
        })
    }

    get forwardPossible() {
        return this.history.forwardPossible;
    }

    goFwd() {
        this.history.goFwd((stackItem: Historyitem, previousStackItem: Historyitem | undefined) => {
            this.switchSelectorsAndSelect(stackItem.selections, previousStackItem?.selections);
            this.graphFilters.setSettings(stackItem.graphFilters)
        })
    }

    /**
     * with the exception of the active selector, which initiated a change in the
     * selection and which is therefore "up to state", deactivate the selectors which were previously
     * active and activate those, which are in the current selection.
     */
    private switchSelectorsAndSelect(currentSelection: MultiDimSelection | undefined,
        previousSelection: MultiDimSelection | undefined = undefined,
        activeSelectorId: SelectorId | undefined = undefined) {

        for (const dimension of selectorDimensions) {
            const previousSelectorId = previousSelection?.get(dimension)?.selectorId;
            const currentSelectorId = currentSelection?.get(dimension)?.selectorId;

            /**
             * un-select previous control if different from current and different from active control
             */
            if (previousSelectorId
                && previousSelectorId !== currentSelectorId
                && previousSelectorId !== activeSelectorId) {
                this.selectors.get(previousSelectorId)?.setState(undefined);
            }

            /**
             * select current control if different from active selector. The active selector would
             * already be activated by user interaction.
             */
            if (currentSelectorId
                && currentSelectorId !== activeSelectorId) {
                this.selectors.get(currentSelectorId)?.setState(currentSelection.get(dimension)?.selectorState);
            }
        }
        this.doSelect(currentSelection);
    }

    stackState(): StackState {
        const localItems = this.history
            .filter((historyItem) => this.hasInGraphSelection(historyItem.selections)).length;
        const totalItems = this.history.length;
        const historyPos = this.history.historyPos;
        return { totalItems, historyPos, localItems }
    }

    private hasInGraphSelection(selection: MultiDimSelection) {
        return Array.from(selection.values()).find(singleDimSelection => {
            const selectorProfile = this.selectors.get(singleDimSelection.selectorId);
            return selectorProfile?.kind === "in-graph"
        }) !== undefined
    }
}

export type GraphInteractionControlProps = {
    interactionController: GraphInteractionController;
}
