import { Icon } from '@blueprintjs/core';
import React from 'react';
import { sendRequest } from '../Request.js';
import { Decycler } from '@insight/common/decycler/Decycler.js';
import { CaseFilterType } from './CaseFilterType.js';
import { CaseFilter } from './CaseFilter.js';
import { CaseFilterChoicesFieldDefinition, CaseFilterFieldDefinition, CaseFilterSelectFieldDefinition, FieldType } from './CaseFilterFieldDefinition.js';
import { CaseFilterField } from './CaseFilterField.js';
import { IntlShape } from 'react-intl';

export class CaseFilterEventAttribute extends CaseFilter {
    data!: CaseFilter['data'] & {
        operators: string[] | undefined;
        commonValues: string[] | undefined;
    }

    constructor(name: string,
        description: string | undefined,
        type: CaseFilterType,
    ) {
        super(name, description, type);
    }

    initData() {
        super.initData();
        this.data = {
            ...this.data,
            operators: [],
            commonValues: []
        }
        this.configureFields();
    }

    onGraphAssigned(): void {
        this.configureFields();
    }

    execute(eventsfile: string) {
        const query = new URLSearchParams({
            eventsfile,
        });
        return sendRequest<number[], { [key: string]: unknown }>("event_attribute_filter?" + query.toString(), {
            method: "post",
            data: this.fieldValues()
        })
    }

    private configureFields() {
        const attributeName = this.fields["attributeName"].value;
        if (this.data.graph && attributeName && typeof attributeName === "string") {
            const selectedAttribute = this.data.graph.eventAttributeDefinitions.get(attributeName)
            if (selectedAttribute) {
                Object.values(this.fields).forEach(field => {
                    switch (field.fieldDefinitionCopy.id) {
                        case "attributeName": {
                            if (field.value !== undefined && typeof field.value === "string" && this.data.graph) {
                                switch (selectedAttribute.data.datatype) {
                                    case "string":
                                        this.data.operators = ["=", "in"];
                                        this.fields["operator"].fieldDefinitionCopy.visible = true;
                                        this.data.commonValues = selectedAttribute?.stringValues();
                                        // this.fields["attributeValue"].fieldDefinitionCopy.type = FieldType.STRING;
                                        break;
                                    case "number":
                                        this.data.operators = ["<", "=", ">"];
                                        this.fields["operator"].fieldDefinitionCopy.visible = true;
                                        // this.fields["attributeValue"].fieldDefinitionCopy.type = FieldType.FLOAT;
                                        break;
                                    default:
                                        this.fields["operator"].fieldDefinitionCopy.visible = false;
                                        this.data.operators = []
                                    // this.fields["attributeValue"].fieldDefinitionCopy.type = FieldType.STRING;
                                }
                            }
                            break;
                        }
                        case "operator": {
                            if (field.value === undefined) {
                                this.fields["numAttributeValue"].fieldDefinitionCopy.required = false;
                                this.fields["numAttributeValue"].fieldDefinitionCopy.visible = false;
                                this.fields["strAttributeValue"].fieldDefinitionCopy.required = false;
                                this.fields["strAttributeValue"].fieldDefinitionCopy.visible = false;
                                this.fields["strArrayAttributeValue"].fieldDefinitionCopy.visible = false;
                                this.fields["strArrayAttributeValue"].fieldDefinitionCopy.required = false;
                            }
                            else if (field.value === "in") {
                                this.fields["numAttributeValue"].fieldDefinitionCopy.required = false;
                                this.fields["numAttributeValue"].fieldDefinitionCopy.visible = false;
                                this.fields["strAttributeValue"].fieldDefinitionCopy.required = false;
                                this.fields["strAttributeValue"].fieldDefinitionCopy.visible = false;
                                this.fields["strArrayAttributeValue"].fieldDefinitionCopy.visible = true;
                                this.fields["strArrayAttributeValue"].fieldDefinitionCopy.required = true;
                            }
                            else {
                                if (selectedAttribute.data.datatype === "string") {
                                    this.fields["numAttributeValue"].fieldDefinitionCopy.required = false;
                                    this.fields["numAttributeValue"].fieldDefinitionCopy.visible = false;
                                    this.fields["strAttributeValue"].fieldDefinitionCopy.required = true;
                                    this.fields["strAttributeValue"].fieldDefinitionCopy.visible = true;
                                    this.fields["strArrayAttributeValue"].fieldDefinitionCopy.visible = false;
                                    this.fields["strArrayAttributeValue"].fieldDefinitionCopy.required = false;
                                }
                                else if (selectedAttribute.data.datatype === "number") {
                                    this.fields["numAttributeValue"].fieldDefinitionCopy.required = true;
                                    this.fields["numAttributeValue"].fieldDefinitionCopy.visible = true;
                                    this.fields["strAttributeValue"].fieldDefinitionCopy.required = false;
                                    this.fields["strAttributeValue"].fieldDefinitionCopy.visible = false;
                                    this.fields["strArrayAttributeValue"].fieldDefinitionCopy.visible = false;
                                    this.fields["strArrayAttributeValue"].fieldDefinitionCopy.required = false;
                                }
                            }
                        }
                    }

                })
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange(field: CaseFilterField, value: string) {
        this.configureFields();
    }

    getOptions(field: CaseFilterField): string[] | undefined {
        let result;
        switch (field.fieldDefinitionCopy.id) {

            case "attributeName":
                const data = this.data.graph !== undefined ? [...this.data.graph.eventAttributeDefinitions.keys()].sort() : [];
                return data;

            case "operator":
                return this.data.operators;

            case "strAttributeValue":
            case "strArrayAttributeValue":
                return this.data.commonValues?.sort();
        }
        return result;
    }

    label(intl: IntlShape) {
        let parts: string[] = [];
        if (this.fields.attributeName && this.fields.operator) {
            parts = [
                this.fields.attributeName.toString(intl.locale),
                this.fields.operator.toString(intl.locale),
            ]
            if (this.fields.numAttributeValue.fieldDefinitionCopy.visible) {
                parts.push(this.fields.numAttributeValue.toString(intl.locale))
            }
            else if (this.fields.strAttributeValue.fieldDefinitionCopy.visible) {
                parts.push(this.fields.strAttributeValue.toString(intl.locale))
            }
            else if (this.fields.strArrayAttributeValue.fieldDefinitionCopy.visible) {
                parts.push(this.fields.strArrayAttributeValue.toString(intl.locale));
            }
        }
        return parts.join(" ");
    }
}
Decycler.registerSerializableType(CaseFilterEventAttribute);

new CaseFilterType({
    id: "CaseFilterEventAttribute",
    name: intl => intl.formatMessage({
        id: "filter.def.event-filter-attribute.name",
        defaultMessage: "Attribute",
        description: "Name of event attributes filter.",
    }),
    description: intl => intl.formatMessage({
        id: "filter.def.event-filter-attribute.description",
        defaultMessage: 'Prozesse anzeigen, auf die der Vergleich zutrifft',
        description: "Description of case filter",
    }),
    fieldDefinitions: [
        new CaseFilterChoicesFieldDefinition({
            id: "attributeName",
            name: intl => intl.formatMessage({
                id: "filter.def.event-filter-attribute.attribute",
                defaultMessage: "Eventattribute filter",
                description: "Description select label for case attribute",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: true,
            vector: false,
        }),

        new CaseFilterChoicesFieldDefinition({
            id: "operator",
            name: intl => intl.formatMessage({
                id: "filter.def.operator",
                defaultMessage: "Operator",
                description: "Name of \"operator\" parameter",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: false,
            vector: false,
        }),

        new CaseFilterFieldDefinition({
            id: "numAttributeValue",
            name: intl => intl.formatMessage({
                id: "filter.def.compare-value",
                defaultMessage: 'Vergleichswert',
                description: "Name of reference value to compare to",
            }),
            required: true,
            visible: false,
            vector: false,
            type: FieldType.FLOAT,
            description: () => "",
            placeholder: () => "",
        }),

        new CaseFilterChoicesFieldDefinition({
            id: "strAttributeValue",
            name: intl => intl.formatMessage({
                id: "filter.def.compare-value",
                defaultMessage: "Vergleichswert",
                description: "Name of reference value to compare to",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: false,
            vector: false,
        }),

        new CaseFilterSelectFieldDefinition({
            id: "strArrayAttributeValue",
            name: intl => intl.formatMessage({
                id: "filter.def.filter-attribute-array.value",
                defaultMessage: "Selection",
                description: "Label for set of values to select from",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: false,
            vector: true,
        }),
    ],
    childrenAllowed: false,
    icon: <Icon icon="filter" />,
    class: CaseFilterEventAttribute,
})