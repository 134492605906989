/* eslint-disable @typescript-eslint/ban-types */
/*
    Decycler
*/
import assert from "assert";
import SortedArray from "collections/sorted-array.js";
import * as CryptoJS from 'crypto-js';
import { TypeWithVersionInfo } from "./VersionedConstructor.js";

interface BoundaryType { id: string }
function isBoundaryType(object: unknown, bt: GetableTypes): object is BoundaryType {
    return typeof object === 'object' &&
        object !== null &&
        object.constructor.name in bt;
}


/**
 * A getable type is a class type which provides a  (static) method to get an instance of that type
 * by some id from a static store inside that type. Instances of such classes need to have an "id"
 * property in order for this to work.
 */
interface TypeGetableById extends Function {
    getById: (id: string) => unknown;
}
type GetableTypes = { [key: string]: TypeGetableById };

interface BoundaryDescriptor {
    __type: string;
    __id: string;
    __prop: string
}
function isBoundaryDescriptor(object: unknown): object is BoundaryDescriptor {
    return typeof object === 'object' && object !== null
        && "__type" in object && typeof object.__type === "string"
        && "__id" in object && typeof object.__id === "string"
        && "__prop" in object && typeof object.__prop === "string";
}

type Record = { [key: string]: unknown }
type NonNullObject = Function | Object | Record;
function isNonNullObject(value: unknown): value is NonNullObject {
    return (typeof value === "object" || typeof value === "function") && value !== null;
}

type SerializableObject =
    (
        {
            $$id: number
            $$class?: string,
        }
        & (
            {
                $$props?: { [key: string]: Serializable },
                $b?: BoundaryDescriptor[]
            }
            | { $$arr: Serializable[] }
            | { $$map: { key: string, value: Serializable }[] }
            | { $$set: Serializable[] }
            | {
                $$hv: Serializable,
                $$class: string,
            }
            | { $$constructor: string }
            | { $$function: string }
            | {}
        )
    )

type ObjectReference = { $$ref: number }

type SerializablePrimitive =
    undefined | boolean | number | string | bigint | string | symbol;

type ClassVersions = { [key: string]: TypeWithVersionInfo };
export type VersionedValue = {
    $$classVersions: ClassVersions
    $$decyclerVersion: string,
    value: Serializable;
}

export type Serializable = SerializableObject | ObjectReference | SerializablePrimitive | null;

export type Hooks = { [key: string]: (value: object) => object };

const maybeFunctionKeys = Object.keys(Object.getOwnPropertyDescriptors(function (a: unknown) { return a })); // keys of a Function object for a function with parameters

/**
 * A decycler decycles an object so that it das not contain any circular
 * references within its structure and can be JSON stringified without
 * problems.
 * It also retro-cycles that object from a given de-cycled version.
 * To make a class de- and retro-cyclable, it needs to be registered, either
 * globally or with a specificy Decycler instance.
 * Decycler supports so called "BoundaryClasses" which are classes who's
 * instances have a static "getById()" method and a string-typed "id" property. Their
 * instances are not included in the decycled object but are later re-attached
 * to the data structure by using getById() to get a reference to the
 * respective instance and by storing the id in the de-cyclce structure.
 */
export class Decycler {

    private static serializableBaseTypes: { [key: string]: Function } = {
        Array: Array,
        Map: Map,
        Set: Set,
        RegExp: RegExp,
        Date: Date,
        Boolean: Boolean,
        Number: Number,
        String: String,
        Function: Function,
        // Object: Object,
    }

    /**
     * The classes which are serialized / deserialized by this decycler. It can be
     * extended by registering more serializer types - globally or per Decycler
     */
    private static globalSerializableTypes: { [key: string]: TypeWithVersionInfo } = {};

    private static globalBoundaryTypes: GetableTypes = {};

    private static saveHooks: Hooks = {};
    private static loadHooks: Hooks = {};

    static setSaveHook<T>(type: Function, hook: (value: T) => object) {
        this.saveHooks[type.name] = hook as (value: object) => object;
    }

    static setLoadHook<T>(type: Function, hook: (value: T) => object) {
        this.loadHooks[type.name] = hook as (value: object) => object;
    }

    static registerBoundaryType(type: TypeGetableById) {
        this.globalBoundaryTypes[type.name] = type;
    }

    static registerBoundaryTypes(types: TypeGetableById[]) {
        types.forEach(type => {
            this.registerBoundaryType(type);
        })
    }

    static registerSerializableType(type: Function) {
        this.globalSerializableTypes[type.name] = new TypeWithVersionInfo(type);
    }

    static registerSerializableTypes(types: Function[]) {
        types.forEach(type => {
            this.registerSerializableType(type);
        })
    }

    private static findAncestorConstructorWithHook(value: object, hooks: Hooks) {
        let constructor = value.constructor;
        while (constructor !== null && !(constructor.name in hooks)) {
            constructor = Object.getPrototypeOf(constructor);
        }
        return constructor;
    }

    private saveHooks: Hooks // hooks to change objects before they are serialized
    private loadHooks: Hooks // hooks to chnage de-serialized objects before they are passed back for usage
    private getableTypes: GetableTypes;

    /** The types of values, which are serialized / deserialized by this decycler */
    private types: { [key: string]: TypeWithVersionInfo };

    private $$id: number;
    private recycledObjects!: SortedArray<any>;
    private recursionLimit: number;
    private decycledObjects: Map<unknown, number>; // maps decycled objects to their id

    constructor() {
        this.$$id = 1;
        this.recursionLimit = 100;
        this.loadHooks = { ...Decycler.loadHooks };
        this.saveHooks = { ...Decycler.saveHooks };
        this.types = { ...Decycler.globalSerializableTypes };
        this.getableTypes = { ...Decycler.globalBoundaryTypes };
        this.decycledObjects = new Map<unknown, number>();
    }

    registerBoundaryType(type: TypeGetableById) {
        this.getableTypes[type.name] = type;
    }

    registerBoundaryTypes(types: TypeGetableById[]) {
        types.forEach(type => {
            this.registerBoundaryType(type);
        })
    }

    registerSerializableType(type: Function) {
        this.types[type.name] = new TypeWithVersionInfo(type);
    }

    registerSerializableTypes(types: Function[]) {
        types.forEach(type => {
            this.registerSerializableType(type);
        })
    }

    setSaveHook<T>(type: Function, hook: (value: T) => object) {
        this.saveHooks[type.name] = hook as (value: object) => object;
    }

    setLoadHook<T>(type: Function, hook: (value: T) => object) {
        this.loadHooks[type.name] = hook as (value: object) => object;
    }


    static _depth = 0;

    /**
     * Dereference a value by recursing through its properties, if any, and
     * store references only once. Any double reference will
     *
     * @param value Value of any type to be dereferenced
     * @returns Dereferenced Value
     */
    private decycleAndRecurse(value: unknown): Serializable {
        let nu: Serializable;
        Decycler._depth++;
        if (Decycler._depth > this.recursionLimit) {
            throw new Error(`Dereferentiation exceed the recursion limit of ${this.recursionLimit}`);
        }

        // typeof null === "object", so go on if this value is really an object but not
        // one of the builtin objects.
        switch (typeof value) {

            case "undefined":
            case "boolean":
            case "number":
            case "bigint":
            case "string":
            case "symbol": {
                nu = value;
                break;
            }

            case 'object':
            case 'function': {
                let no: SerializableObject | ObjectReference //= { $$id: -1 }
                if (isNonNullObject(value)) {
                    const cls_name: string = value.constructor.name
                    if (cls_name in this.types || cls_name in Decycler.serializableBaseTypes) {
                        let id = this.decycledObjects.get(value);
                        if (id !== undefined) {
                            no = { $$ref: id }
                        } else {
                            let $$id = this.$$id++;
                            this.decycledObjects.set(value, $$id); // remember original object
                            const constructor = Decycler.findAncestorConstructorWithHook(value, this.saveHooks);
                            if (constructor !== null) {
                                const hookValue = this.saveHooks[constructor.name](value) // new_value might be the *same* or a new value
                                const so = this.retrieveStandardTypesDataAndOtherKeys(hookValue);
                                so.$$id = $$id; // serialized hooked value get same id as hooked value record so it can be referenced later under this id
                                no = {
                                    $$id: -1,
                                    $$class: constructor.name,
                                    $$hv: so
                                }
                            }
                            else {
                                no = this.retrieveStandardTypesDataAndOtherKeys(value);
                            }
                            no.$$id = $$id;
                        }
                    }
                    else {
                        throw new Error(`Unknown class '${cls_name}'.`)
                    }
                    nu = no;
                }
                else {
                    nu = null;
                }
                break;
            }
        }
        Decycler._depth--;
        return nu;
    }

    /**
     *
     * @param value
     * @param no
     * @returns {no, keys} tuple. keys undefined if value has non-build-in constructor. keys=[] if value is build-in-constructor. keys=[...] (len>0) Serializable object no updated with data from found standard types. Keys
     */
    private retrieveStandardTypesDataAndOtherKeys(
        value: NonNullObject,
    ): SerializableObject {
        let keys: string[] = [];
        let no: SerializableObject;
        const constructor = Object.values(Decycler.serializableBaseTypes).find(constructor => {
            return value instanceof constructor;
        });
        const cls_name = value.constructor.name;
        if (constructor) {
            switch (constructor.name) {
                case "Array": {
                    /* walk through the array and recurse into dereferencialization */
                    if (value instanceof Array) {
                        no = {
                            $$id: -1,
                            $$arr: [],
                        };
                        for (const element of value) {
                            no.$$arr.push(element != null ? this.decycleAndRecurse(element) : null);
                        }
                        const arrayKeys = Object.keys([...value]); // gets only the array keys
                        keys = Object.keys(value).filter(item => !arrayKeys.includes(item));
                    }
                    else {
                        throw new Error(`Array expected`)
                    }
                    break;
                }

                case "Map": {
                    if (value instanceof Map) {
                        /* walk through the map and recurse into dereferencialization */
                        no = {
                            $$id: -1,
                            $$map: [],
                        }
                        for (const [key, item] of value) {
                            no.$$map.push({ key, value: this.decycleAndRecurse(item) })
                        }
                        keys = Object.keys(value);
                    }
                    else {
                        throw new Error(`Map expected`)
                    }
                    break;
                }
                case "Set": {
                    if (value instanceof Set) {
                        no = {
                            $$id: -1,
                            $$set: [],
                        };
                        for (const v of [...value]) {
                            no.$$set.push(this.decycleAndRecurse(v))
                        }
                        keys = Object.keys(value)
                    }
                    else {
                        throw new Error(`Set expected`)
                    }
                    break;
                }

                case "Function": {
                    if (value instanceof Function) {
                        // (value as FunctionMaybeWithId).$$id = this.$$id++;
                        if ((value.name in this.types || value.name in Decycler.serializableBaseTypes)) {
                            /* reference to a class in the object */
                            no = {
                                $$id: -1,
                                $$constructor: value.name,
                            }
                        } else {
                            /* a function in the object */
                            no = {
                                $$id: -1,
                                $$function: value.toString(),
                            }
                        }
                        keys = Object.keys(value).filter(item => !maybeFunctionKeys.includes(item));
                    }
                    else {
                        throw new Error(`Function expected`)
                    }
                    break;

                }

                default: {
                    throw new Error(`Untreated constructor ${constructor.name}`)
                }
            }
            if (constructor.name !== cls_name) {
                no.$$class = cls_name;
            }
        }
        else {
            keys = Object.keys(value);
            no = {
                $$id: -1,
                $$class: value.constructor.name,
            }
        }

        keys = keys.filter(key => !["$$id"].includes(key))
        if (keys.length > 0) {
            const $$props: { [key: string]: Serializable | null } = {};
            const $b: BoundaryDescriptor[] = [];

            for (const key of keys) {
                const propValue = (value as Record)[key];
                if (isBoundaryType(propValue, this.getableTypes)) {
                    if (propValue.id !== undefined) {
                        const id = propValue.id;
                        const v: BoundaryDescriptor = { __type: propValue.constructor.name, __id: id, __prop: key };
                        $b.push(v);
                    }
                    else {
                        throw new Error(`Boundary class ${propValue.constructor.name} needs an id property.`)
                    }
                }
                else {
                    $$props[key] = propValue !== null ? this.decycleAndRecurse(propValue) : null;
                }
            }
            no = ($b.length === 0) ? { ...no, $$props } : { ...no, $$props, $b }
        }
        return no;
    }

    decycle(object: unknown) {
        this.$$id = 1;
        const result = this.decycleAndRecurse(object);
        return this.getVersionedValue(result)
    }

    /**
     * makeObject, if passed an object, recurses through
     * the object and rebuilds the object of their original
     * class.
     * @param value
     * @returns
     */
    private retrocycleAndRecurse(value: Serializable) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let nu: any = value; // return value by default for primitives including null and refs.

        if (value && typeof value === "object") {
            if ("$$id" in value) {
                nu = {};
                if ("$$hv" in value && "$$class" in value) {
                    if (value.$$class in this.loadHooks) {
                        const obj = this.retrocycleAndRecurse(value.$$hv) as object
                        const hook = this.loadHooks[value.$$class];
                        nu = hook(obj);
                    }
                    else {
                        throw new Error(`No load hook found for class ${value.$$class}.`)
                    }
                } else {
                    if ("$$function" in value) {
                        assert(typeof value.$$function === "string");

                        /** replace "function" by "function name" */
                        const tmp = 'nu =' + value.$$function;
                        // eslint-disable-next-line no-useless-catch
                        try {
                            eval(tmp);
                        }
                        catch (err) {
                            console.log(`Syntax error in '${tmp}'?`);
                            throw err;
                        }
                    }
                    else if ("$$constructor" in value && typeof value.$$constructor === "string") {
                        if (value.$$constructor in this.types) {
                            nu = this.types[value.$$constructor].type
                        }
                        else {
                            throw new Error(`${value.$$constructor} is not registered.`)
                        }
                    }
                    else if ("$$arr" in value && value.$$arr instanceof Array) {
                        /* make an array */
                        nu = value.$$arr.map((e) => {
                            if (e !== null) {
                                return this.retrocycleAndRecurse(e);
                            }
                            else {
                                return null;
                            }
                        })
                    }
                    else if ("$$map" in value && value.$$map instanceof Array) {
                        /* make a map */
                        const m = new Map();
                        value.$$map.forEach((e) => {
                            if (typeof e === "object" && e !== null && "key" in e && "value" in e) {
                                m.set(e.key, this.retrocycleAndRecurse(e.value));
                            }
                        })
                        nu = m;
                    }
                    else if ("$$set" in value && value.$$set instanceof Array) {
                        /* make a set */
                        const m = new Set();
                        value.$$set.forEach(e => {
                            m.add(this.retrocycleAndRecurse(e));
                        })
                        nu = m;
                    }

                    if ("$$class" in value) {
                        assert(typeof value.$$class === "string");
                        if (value.$$class in this.types) {
                            /* make an object of a class */
                            const prototype = this.types[value.$$class].type.prototype
                            nu.__proto__ = prototype;
                        }
                        else {
                            throw new Error(`${value.$$class} is not registered.`)
                        }
                    }

                    if ("$$props" in value) {
                        assert(typeof value.$$props === "object");
                        for (const name of Object.keys(value.$$props)) {
                            let tmp = (value.$$props as { [key: string]: Serializable })[name];
                            if (tmp !== null) {
                                tmp = this.retrocycleAndRecurse(tmp)
                            }
                            nu[name] = tmp;
                        }
                    }

                    if ('$b' in value) {
                        if (value.$b instanceof Array) {
                            assert(value.$b.every($b => isBoundaryDescriptor($b)), `All members of $b property must be of class BoundaryDescriptor.`);
                            /**
                             * reassign connections to boundary objects, i.e. objects
                             * which are not part of the serialized object
                             */
                            value.$b.forEach((b: BoundaryDescriptor) => {
                                const type = this.getableTypes[b.__type];
                                if (type && type['getById']) {
                                    const referredObject = type.getById(b.__id);
                                    nu[b.__prop] = referredObject;
                                }
                                else {
                                    throw new Error(`class ${type.name} is expeted to have a static "getById(id)" method.`);
                                }
                            })
                            delete value.$b;
                        }
                        else {
                            throw new Error(`value property $b should be an array.`)
                        }
                    }
                }
                nu.$$id = value.$$id;
                this.recycledObjects.add(nu); // add to original objects
            }
            else {
                nu = { ...value }; // copy ref object
            }
        }
        return nu;
    }

    /**
     * The rez function walks recursively through the object looking for $ref
     * properties. When it finds one that has a value that is a path, then it
     * replaces the $ref object with a reference to the value that is found by
     * the path.
     * @param value
     * @returns
     */
    private dereference(value: unknown) {
        if (isNonNullObject(value)) {
            let keys: string[] = [];
            if ('$$id' in value) {
                if (value instanceof Array) {
                    for (let i = 0; i < value.length; i++) {
                        value[i] = this.dereference(value[i]);
                    }
                    const arrayKeys = Object.keys([...value]); // gets only the array keys
                    keys = Object.keys(value).filter(item => !arrayKeys.includes(item));

                } else if (value instanceof Set) {
                    const items = [...value];
                    for (const item of items) {
                        value.delete(item);
                        value.add(this.dereference(item));
                    }
                    keys = Object.keys(value);
                }
                else if (value instanceof Map) {
                    for (const [key, item] of value) {
                        value.set(key, this.dereference(item))
                    }
                    keys = Object.keys(value);
                }
                else if (value instanceof Function) {
                    keys = Object.keys(value).filter(item => !maybeFunctionKeys.includes(item));
                }
                else {
                    keys = Object.keys(value);
                }

                for (const name of keys) {
                    if (name !== '$$id') {
                        (value as Record)[name] = this.dereference((value as Record)[name]);
                    }
                }
            }
            else if ('$$ref' in value) {
                const tmp = this.recycledObjects.get({
                    $$id: value.$$ref
                });
                delete value.$$ref; // remove $$ref from value (in recycledObjects!) to avoid recursion on this value
                value = tmp;
            }
        }
        return value;
    }

    getVersionedValue(value: Serializable): VersionedValue {
        return {
            $$classVersions: this.types,
            $$decyclerVersion: decyclerMD5,
            value,
        }
    }

    checkVersionedValue(vValue: VersionedValue) {
        const result = decyclerMD5 === vValue.$$decyclerVersion
            && Object.keys(this.types).every(key => {
                return key in vValue.$$classVersions
                    && this.types[key].compare(vValue.$$classVersions[key].toJSON())
            })
        return result;
    }

    retrocycle(obj: VersionedValue) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.recycledObjects = new SortedArray<any>([], (a, b) => a.$$id === b.$$id, (a, b) => Math.sign(a.$$id! - b.$$id!));
        const nu = this.retrocycleAndRecurse(obj.value);

        /* Resolve references */
        const result = this.dereference(nu);

        /* Cleanse object from $$ids */
        this.recycledObjects.array.forEach(obj => {
            delete obj.$$id;
        });

        return result;
    }
}
Decycler.registerSerializableType(Object);
const decyclerMD5 = CryptoJS.MD5(Decycler.toString()).toString(CryptoJS.enc.Hex)