import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import Project from "@insight/common/interface/Project.js";
import { Button } from "@blueprintjs/core";
import { FilterCreationDialog } from "./FilterCreationDialog/FilterCreationDialog.js";
import { SelectTree } from "./SelectTree2.js";
import { DragPositions } from "./SelectTreeLabel.js";
import { CaseFilterCollection } from "../../classes/case_filter/CaseFilterCollection.js";
import { CaseFilter } from '../../classes/case_filter/CaseFilter.js';
import { DotGraph } from '@insight/common/dot_graph/dotgraph.js';
import log from 'loglevel';
import { DataControllerContext } from '../DataProvider2.js';
import { WidgetControlContext } from '../WidgetProvider.js';
import { GraphInteractionControlProps } from '../View/GraphInteractionController.js';

export type FilterManagerProps = {
    caseFilters: CaseFilter[];
    graph: DotGraph;
    project: Project;
    heading: boolean;
} & GraphInteractionControlProps;

export interface FilterManagerContext {
    createFilter: () => void;
    editFilter: (filter: CaseFilter) => void;
    removeFilter: (filter: CaseFilter) => void;
}

export interface SelectTreeDialogState {
    open: boolean;
    editFilter: CaseFilter | undefined;
    parentFilter: CaseFilter | undefined;
}

export let filterManagerContext: React.Context<FilterManagerContext>;

const CONTROL_ID = "filtertree";

/**
 * Base componnent for case filter management. Selection of filters,
 * opening dialogs for creation, change and deletion of filters are
 * managed by this component.
 *
 * @param props
 * @returns
 */
// let prevCaseFilters: CaseFilterCollection | undefined = undefined;
export function FilterManager(props: FilterManagerProps) {

    /** no effect dependencies => run once per render */
    React.useEffect(() => {
        log.debug("*** Render FilterManager")
    })

    const intl = useIntl(); // https://formatjs.io/docs/react-intl/api/#useintl-hook

    const dataControl = React.useContext(DataControllerContext);
    const widgetControl = React.useContext(WidgetControlContext);

    /*********************************************************************************************
     * store for current case filters. The function passed to useState is run only once,
     * so caseFilters remains unchanged.
     */
    const [caseFilters, setCaseFilters] = React.useState<CaseFilterCollection>(() => {
        return new CaseFilterCollection(props.caseFilters, false);
    })

    /*********************************************************************************************
     * store for selected, i.e. acitve case filters. selected filteres are maintained on this
     * component level because if a selected filter is edited, the case selection needs to be
     * updated.
     */
    const [selectedFiltersIds, setSelectedFiltersIds] = React.useState<number[]>([]);

    /**
     * update case filters when they change
     */
    React.useEffect(() => {
        setCaseFilters(new CaseFilterCollection(props.caseFilters, false));
    }, [props.caseFilters])

    /*********************************************************************************************
     * management of creation and change dialog
     */
    const [dialogState, setFilterDialogState] = React.useState<SelectTreeDialogState>({
        open: false,
        editFilter: undefined,
        parentFilter: undefined
    });

    /**********************************************************************************************
     * register callback for central selection changes and initialzation of selected filters
     */
    React.useEffect(() => {
        props.interactionController.registerSelector(CONTROL_ID, {
            dimension: "process",
            kind: "ex-graph",
            setState: (data: unknown) => {
                setSelectedFiltersIds(data ? data as number[] : []);
            }
        })
        setSelectedFiltersIds([]);
    }, []);

    const updateState = (setDirty: boolean) => {
        setCaseFilters(new CaseFilterCollection(caseFilters.asArray(), setDirty)); // new state must be a new object
    }

    const createFilter = () => {
        openDialog(undefined,undefined);
    }

    const editFilter = (filter: CaseFilter) => {
        openDialog(filter, filter.parent)
    }

    /**
     * Store filter. If it is new, add it to the collection of
     * update it if edited an existing one.
     * @param {*} filter : ;
     */
    const storeFilter = (filter: CaseFilter) => {
        if (dialogState.editFilter === undefined || filter.id !== dialogState.editFilter.id) {
            /** new filter */
            caseFilters.add(filter, dialogState.parentFilter);
        }
        else {
            /* edited an existing filter, update caseIds if this is a
               currently selected filter */
            caseFilters.updateTreeFor(filter);
            const ancestors = filter.ancestors;

            if (ancestors.findIndex(a => selectedFiltersIds.includes(a.id)) >= 0) {
                selectCases(selectedFiltersIds);
            }
        }
        updateState(true);
        closeDialog();
    }

    /**
     * Move a filter within the hierachy (drag & drop operation)
     */
    const moveFilter = React.useCallback((item: CaseFilter, position: DragPositions, target: CaseFilter) => {
        caseFilters.move(item, position, target);
        updateState(true);
    }, [caseFilters])

    /**
     * Remove a filter.
     * @param filter the filter to be removed
     */
    const removeFilter = React.useCallback((filter: CaseFilter) => {
        /* deselect this filter, if it is selected */
        if (selectedFiltersIds.includes(filter.id)) {
            setSelectedFiltersIds(selectedFiltersIds.filter(id => id !== filter.id));
        }
        caseFilters.remove(filter);
        updateState(true);
    }, [caseFilters]);

    filterManagerContext = React.createContext<FilterManagerContext>({
        createFilter,
        editFilter,
        removeFilter,
    });

    /**
     * Open the dialog to edit or create a filter
     * @param {*} event
     * @param {CaseFilter|null} editFilter null for new filter, else filter to be edited
     * @param {CaseFilter|null} parentFilter null for filter on top level, else parent filter
     */
    const openDialog = (editFilter: CaseFilter | undefined, parentFilter: CaseFilter | undefined) => {
        setFilterDialogState({
            ...dialogState,
            editFilter, // the filter to edit
            parentFilter,
            open: true,
        });
    }

    /**
     * Close the filter creation/editing filter
     */
    const closeDialog = () => {
        setFilterDialogState({
            ...dialogState,
            open: false,
        });
    }

    /**
     * Select cases based on the selected filters
     * @param selectedFiltersIds
     */
    const selectCases = (selectedFiltersIds: number[]): void => {
        /* determine and communicate selected filters */
        let iIdx = 0;
        let caseIds: number[] = [];

        const addCases: () => Promise<number[]> = function () {
            if (iIdx < selectedFiltersIds.length) {
                const filter = caseFilters.filterRegistry.get({ id: selectedFiltersIds[iIdx++] });
                if (filter) {
                    return filter.execute(props.project.eventsFilePath, props.graph)
                        .then(newCaseIds => {
                            if (newCaseIds !== undefined) {
                                caseIds = [...new Set([...caseIds, ...newCaseIds])];
                            }
                            return addCases();
                        })
                }
                else {
                    return Promise.reject(new Error("No case filter found"));
                }
            }
            else {
                return Promise.resolve(caseIds);
            }
        }

        const promise = ((selectedFiltersIds.length > 0)
            ? addCases()
            : Promise.resolve(undefined));
        promise.then((caseIds) => {
            props.interactionController.select({
                caseIds,
                selectorId: CONTROL_ID,
                selectorState: [...selectedFiltersIds],
            });
        })

    }

    /** render the dialog */
    return (
        <div className='selector' id="filter-manager">
            {props.heading && <h2 className="bp5-heading">
                <FormattedMessage
                    id="filter.variants.ttl"
                    defaultMessage="Case selectors"
                    description="Label for case selector tree titel"
                />
            </h2>
            }
            {
                widgetControl && dataControl !== undefined && dataControl.caseFiltersController !== undefined &&
                <div>
                    <Button
                        text={intl.formatMessage({
                            id: "filter.ui.save_filters",
                            defaultMessage: "Save filters",
                            description: "Button label to save filters.",
                        })}
                        disabled={widgetControl === null}
                        intent={caseFilters.dirty ? "warning" : undefined}
                        onClick={
                            () => {
                                if (dataControl.caseFiltersController) {
                                    widgetControl.overlay.setIsOpen(true);
                                    widgetControl.overlay.setLabel("Saving");
                                    widgetControl.overlay.setProgress(0);
                                    dataControl.caseFiltersController.data = caseFilters.asArray();
                                    dataControl.caseFiltersController.store(widgetControl.overlay.setProgress)
                                        .then(() => {
                                            widgetControl?.overlay.setIsOpen(false);
                                            updateState(false);
                                        })
                                }
                            }
                        }
                    />
                    <Button
                        text={intl.formatMessage({
                            id: "filter.ui.add_filter",
                            defaultMessage: "Add Filter",
                            description: "Menu label to add a filter",
                        })}
                        onClick={() => { createFilter() }}
                    />
                </div>
            }
            <SelectTree
                caseFilters={caseFilters.asArray()}
                handleDrop={moveFilter}
                selectedFiltersIds={selectedFiltersIds}
                selectFiltersIds={React.useCallback((caseFiltersIds: number[]) => {
                    setSelectedFiltersIds(caseFiltersIds);
                    selectCases(caseFiltersIds);
                }, [selectedFiltersIds, caseFilters])}
                project={props.project}
            />
            {dialogState.open &&
                <FilterCreationDialog
                    graph={props.graph}
                    onClose={closeDialog}
                    caseFilter={dialogState.editFilter}
                    storeFilter={storeFilter}
                />}
        </div>

    );
}