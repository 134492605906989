import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartDataset } from 'chart.js'
import { IntlShape, injectIntl } from 'react-intl';
import { DotGraph } from "@insight/common/dot_graph/dotgraph.js";
import { GraphInteractionControlProps } from '../View/GraphInteractionController.js';

import {
    Chart as ChartJS, ActiveElement, ChartEvent, Filler,
    CategoryScale, LogarithmicScale, LinearScale, LineController,
    PointElement, LineElement, BarElement,
} from 'chart.js'
ChartJS.register(LogarithmicScale, CategoryScale, LinearScale, PointElement, LineController, LineElement, BarElement, Filler);

import {
    Button,
    Checkbox,
    MenuItem,
} from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";

import ChartDataStore, {
    ChartType,
    MinMax,
    PartialTimeChartOptions,
    TimeChartOptions
} from "@insight/common/interface/iChart.js";

import Project from '@insight/common/interface/Project.js';
import TimeUnit, { SHORT_UNIT } from '@insight/common/timescale/timeunits.js';
import { getChartData } from './getChartData.js';
import { TimeUnitLabels, getTimeLabels } from './fmtUnit.svc.js';

const max = 150;
const numTickIncrements = [0.5, 1, 2, 2.5]; /* possible Tick increments on a scale of 0-10 */
const MAX_TICKS = 8;

const axis1Color = `#E6710D`;
const axis1Background = axis1Color + "40";
const axis2Color = `#0B91DA`;
const selectColor = `#D70022`;

const defaultChartType: ChartType = "RUNTIME_DIST";

export type TimeChartProps = {
    graph: DotGraph;
    intl: IntlShape;
    project: Project;
} & GraphInteractionControlProps;

export type IntervalSelectionEvent =
    | { type: "ctrl_select", timeIntervalIndex: number }
    | { type: "range_select"; timeIntervalIndex: number }
    | { type: "reset" }

interface TimeChartState {
    selection: number[];
    chartData: ChartDataStore | null;
    chartOptions: TimeChartOptions;
    displayOptions: boolean;
}

class TimeChart extends React.Component<TimeChartProps, TimeChartState> {
    timeUnitLabels: TimeUnitLabels;
    firstSelectionType: 'interval' | 'singles' | undefined = undefined;

    constructor(props: TimeChartProps) {
        super(props);
        this.timeUnitLabels = getTimeLabels(props.intl);

        this.state = {
            chartData: null,
            displayOptions: false,
            selection: [],
            chartOptions: {
                type: defaultChartType,
                clampToZero: false,
                fixMinMax: false,
                minmaxDuration: { min: -1, max: -1 },
                minmaxNumber: { min: -1, max: -1 }
            }
        }

        props.interactionController.registerSelector("run_time_chart", {
            dimension: "runtime",
            kind: "in-graph",
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            setState: (data: unknown) => { return }
        })

        props.interactionController.registerSelector("start_interval_chart", {
            dimension: "start_time_span",
            kind: "in-graph",
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            setState: (data: unknown) => { return }
        })

        props.interactionController.registerSelector("cover_interval_chart", {
            dimension: "cover_time_span",
            kind: "in-graph",
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            setState: (data: unknown) => { return }
        })
    }

    setOptions(options: PartialTimeChartOptions) {
        this.setState({ ...this.state, chartOptions: { ...this.state.chartOptions, ...options } })
    }

    componentDidMount = () => {
        const caseIds = this.props.graph.casesInfo.map((c) => c.id);
        getChartData(this.props.project.eventsFilePath,
            caseIds,
            this.props.intl.locale,
            this.state.chartOptions)
            .then(chartData => this.setState({ ...this.state, chartData }));
    }

    shouldComponentUpdate(nextProps: Readonly<TimeChartProps>): boolean {
        /** skip update if graph changed. It will be rendered by setting a new state after getting the data */
        if ((this.props.graph !== nextProps.graph)) {
            const caseIds = nextProps.graph.casesInfo.map((c) => c.id);
            getChartData(this.props.project.eventsFilePath,
                caseIds,
                this.props.intl.locale,
                this.state.chartOptions)
                .then(chartData => {
                    this.setState({ ...this.state, chartData })
                })
                .catch(err => {
                    console.log(err);
                });
            return false;
        }
        else {
            if (nextProps.intl.locale !== this.props.intl.locale) {
                this.timeUnitLabels = getTimeLabels(nextProps.intl);
            }
            return true
        }
    }

    componentDidUpdate(prevProps: Readonly<TimeChartProps>, prevState: Readonly<TimeChartState>): void {
        if (this.state.chartOptions.type !== prevState.chartOptions.type) {
            const caseIds = this.props.graph.casesInfo.map((c) => c.id);
            getChartData(this.props.project.eventsFilePath, caseIds, this.props.intl.locale, this.state.chartOptions)
                .then(chartData => this.setState({ ...this.state, chartData }))
                .catch(err => {
                    console.log(err);
                });
        }
    }

    componentWillUnmount = () => {
        return;
    }

    render() {
        console.log(`*** Render TimeChart`)
        const intl = this.props.intl;
        const chartData = this.state.chartData;
        if (chartData === null) return null;

        /**
         * Setup x-Axis-Label based on props
         * @returns
         */
        const timeLabel = () => {
            let xunit: SHORT_UNIT = "ms";
            if (chartData) {
                xunit = chartData.xunit;
            }
            return this.timeUnitLabels.singular[xunit];
        }

        const durationLabel = () => {
            let xunit: SHORT_UNIT = "s";
            if (chartData) {
                xunit = chartData.xunit;
            }
            return this.timeUnitLabels.plural[xunit];
        }

        /**
         * setup chart options
         */
        const options: TimeChartOptions = {
            ...this.state.chartOptions,
            maintainAspectRatio: false,
            animation: {
                duration: 0,
            },
            scales: {
                x: {
                    title: {
                        display: true,
                    },
                },
                y1: {
                    position: 'left',
                    title: {
                        display: true,
                        text: intl.formatMessage({
                            id: "timechart.yaxis.duration",
                            defaultMessage: 'Duration [days]',
                            description: "Y-Axis-Label for duration.",
                        })
                    },
                    ticks: {
                    }
                },
            },
            onClick: (e: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
                if (e.native !== null) {

                    /**
                     * get index of clicked bar and append it to selection. if this is the first bar, remember
                     * wether CTRL was clicked which starts selecting individual bars
                     */
                    const points = chart.getElementsAtEventForMode(e.native, 'nearest', { intersect: true }, true);
                    if (points.length > 0) {
                        const ctrlPressed = (e.native as PointerEvent).ctrlKey;
                        this.state.selection.push(points[0].index);
                        if (this.state.selection.length === 1) {
                            this.firstSelectionType = ctrlPressed
                                ? "singles"
                                : "interval"
                        }

                        /**
                         * if last click, collect indexes of selected bars and then get the
                         * case ids of the bards
                         */
                        if (!ctrlPressed && this.state.selection.length >= 2) {

                            let selection = this.state.selection;
                            /**
                             * get all bars between first and last clicked bar if CTRL was not
                             * clicked in the beginning
                             */
                            if (this.firstSelectionType === "interval") {
                                selection = [];
                                let first = this.state.selection[0];
                                let last = this.state.selection[1];
                                if (first > last) {
                                    const tmp = last;
                                    last = first;
                                    first = tmp;
                                }
                                for (let idx = first; idx <= last; idx++) selection.push(idx);
                            }

                            /**
                             * get case ids of call bars and send them to the interaction controller
                             */
                            let caseIds = selection.reduce((ids: number[], idx) => {
                                const chartData = this.state.chartData;
                                if (chartData) {
                                    ids.push(...chartData.cases[idx]);
                                }
                                return ids;
                            }, [] as number[])
                            caseIds = [...new Set([...caseIds])];  // make unique as set, reconvert to array

                            let selectorId: string;
                            switch (this.state.chartOptions.type) {
                                case 'RUNTIME_DIST':
                                    selectorId = "run_time_chart";
                                    break;
                                case 'COVER_INTERVAL':
                                    selectorId = "cover_interval_chart";
                                    break;
                                case 'START_INTERVAL':
                                    selectorId = "start_interval_chart";
                                    break;
                            }

                            this.props.interactionController.select({
                                caseIds,
                                selectorId,
                                selectorState: undefined,
                            });
                            this.setState({ ...this.state, selection: [] });
                            this.firstSelectionType = undefined;
                        }
                        else {
                            this.setState({ ...this.state });
                        }
                    }
                }

            },
            type: this.state.chartOptions.type,
        };

        if (chartData) {

            const data: ChartData = {
                labels: chartData.xlabels, //.slice(0, max),
                datasets: []
            }

            const chartTypeNames: { [key in ChartType]: string } = {
                START_INTERVAL: intl.formatMessage({
                    id: "timechart.charttypes.start_interval",
                    defaultMessage: 'Interval starts',
                    description: "Label for chart type, which show informatoin about processes starting in the intervals.",
                }),
                COVER_INTERVAL: intl.formatMessage({
                    id: "timechart.charttypes.cover_interval",
                    defaultMessage: 'Interval coverage',
                    description: "Label for chart type, which show informatoin about processes covering the intervals.",
                }),
                RUNTIME_DIST: intl.formatMessage({
                    id: "timechart.charttypes.runtime_dist",
                    defaultMessage: 'Runtime distribution',
                    description: "Label for chart type, which shows information about the distribution of run times of processes.",
                })
            }

            let durations: (number | null)[];
            switch (this.state.chartOptions.type) {
                case 'START_INTERVAL':
                case 'COVER_INTERVAL': {

                    /** data and options for axis 1 */
                    durations = chartData.durations.slice(0, max);
                    data.datasets.push({
                        type: 'line',
                        label: intl.formatMessage({
                            id: "timechart.dataset.durations",
                            defaultMessage: '~ case durations [days]',
                            description: "Datasetlabel for case durations.",
                        }),
                        borderColor: axis1Color,
                        backgroundColor: axis1Background,
                        borderWidth: 2,
                        fill: true,
                        data: durations,
                        yAxisID: 'y1',
                        cubicInterpolationMode: 'monotone',
                    });

                    if (options.scales && options.scales.x && options.scales.x.title && options.scales.y1 && options.scales.y1.title) {

                        options.scales.x.title.text = (this.state.chartOptions.type
                            ? chartTypeNames[this.state.chartOptions.type]
                            : "")
                            + " "
                            + intl.formatMessage({
                                id: "timechart.xaxis.foreach",
                                defaultMessage: 'for each',
                                description: "Translation of 'for each'",
                            })
                            + " "
                            + timeLabel();

                        options.scales.y1.min = this.state.chartOptions.fixMinMax
                            ? this.state.chartOptions.minmaxDuration.min
                            : this.state.chartOptions.clampToZero
                                ? 0
                                : Math.min.apply(this, durations as number[]);

                        options.scales.y1.max = this.state.chartOptions.fixMinMax
                            ? this.state.chartOptions.minmaxDuration.max
                            : Math.max.apply(this, durations as number[]);

                        options.scales.y1.title.color = axis1Color;

                        options.scales.y1.afterBuildTicks = (axis) => {
                            const span = axis.max - axis.min;
                            const { timeUnit, intervalSize } = TimeUnit.determineAxisUnitAndIntervalSize(span, MAX_TICKS);
                            const ticks = Math.ceil(span / intervalSize);

                            /* compute min on the basis of the tick increment */
                            axis.min = Math.floor(axis.min / intervalSize) * intervalSize;
                            axis.max = axis.min + intervalSize * ticks;

                            let value = axis.min;
                            axis.ticks = [{ value, label: (value / timeUnit.unit_in_ms).toString() }];
                            for (let i = 0; i < ticks; i++) {
                                value += intervalSize;
                                axis.ticks.push({ value, label: (value / timeUnit.unit_in_ms).toString() });
                            }
                            return;
                        }

                        if (options.scales.y1.ticks) {
                            options.scales.y1.ticks.callback = (duration: number | string) => {
                                duration = typeof duration == "string" ? parseInt(duration) : duration
                                const parts: string[] = [];
                                for (let idx = TimeUnit.sortedTimeUnits.length-1;idx>=0;idx--) {
                                // for (const tu of TimeUnit.sortedTimeUnits) {
                                    const tu = TimeUnit.sortedTimeUnits[idx];
                                    const val = Math.floor(duration / tu.unit_in_ms);
                                    if (val > 0) parts.push(val + this.timeUnitLabels.short[tu.unitLabel]);
                                    duration -= val * tu.unit_in_ms;
                                    if (parts.length === 2) break;
                                }
                                return parts.join(" ");
                            }
                            options.scales.y1.ticks.color = axis1Color;
                        }

                        /** data and options for axis 2 */

                        const backgroundColors = new Array(chartData.xlabels.length).fill(axis2Color);
                        const selection = this.state.selection;
                        if (selection) {
                            selection.forEach(idx => backgroundColors[idx] = selectColor);
                        }

                        const values = chartData.values.slice(0, max);
                        data.datasets.push({
                            type: 'bar',
                            label: intl.formatMessage({
                                id: "timechart.dataset.cases",
                                defaultMessage: '# of running cases',
                                description: "Datasetlabel for number of running cases.",
                            }),
                            backgroundColor: backgroundColors,
                            data: values,
                            yAxisID: 'y2',
                        } as ChartDataset<"bar">);

                        options.scales.y2 = {
                            afterBuildTicks: (axis) => {
                                const span = axis.max - axis.min;
                                const magnitude = Math.floor(Math.log10(span));
                                const magValue = span / Math.pow(10, magnitude); /* 0 - 10 */
                                let tickInterval = numTickIncrements.find((nst, idx) => {
                                    return magValue / nst <= MAX_TICKS || idx === numTickIncrements.length - 1
                                });
                                if (tickInterval) {
                                    const ticks = Math.ceil(magValue / tickInterval);
                                    tickInterval *= Math.pow(10, magnitude);

                                    /* compute min on the basis of the tick increment */
                                    axis.min = Math.floor(axis.min / tickInterval) * tickInterval;
                                    axis.max = axis.min + tickInterval * ticks;

                                    let value = axis.min;
                                    axis.ticks = [{ value, label: value.toString() }];
                                    for (let i = 0; i < ticks; i++) {
                                        value += tickInterval;
                                        axis.ticks.push({ value, label: value.toString() });
                                    }
                                }
                            },
                            position: 'right',
                            min: this.state.chartOptions.fixMinMax ?
                                this.state.chartOptions.minmaxNumber.min :
                                this.state.chartOptions.clampToZero ?
                                    0 :
                                    Math.min.apply(this, values),
                            max: this.state.chartOptions.fixMinMax ?
                                this.state.chartOptions.minmaxNumber.max :
                                Math.max.apply(this, values),
                            title: {
                                display: true,
                                color: axis2Color,
                                text: intl.formatMessage({
                                    id: "timechart.yaxis.cases",
                                    defaultMessage: 'cases [#]',
                                    description: "Y-Axis-Label for number of cases.",
                                })
                            },
                            ticks: {
                                color: axis2Color,
                            }
                        };
                    }
                    break;
                }

                case 'RUNTIME_DIST': {
                    const backgroundColors = new Array(chartData.xlabels.length).fill(axis1Color);
                    const selection = this.state.selection;
                    if (selection) {
                        selection.forEach(idx => backgroundColors[idx] = selectColor);
                    }

                    durations = chartData.durations.slice(0, max);
                    data.datasets.push({
                        type: 'bar',
                        label: intl.formatMessage({
                            id: "timechart.dataset.durations",
                            defaultMessage: '~ case durations [days]',
                            description: "Datasetlabel for case durations.",
                        }),
                        borderColor: axis1Color,
                        backgroundColor: backgroundColors,
                        data: durations,
                        yAxisID: 'y1',
                    } as ChartDataset<'bar'>);

                    if (options.scales && options.scales.x && options.scales.x.title && options.scales.y1 && options.scales.y1.title && options.scales.y1.ticks) {
                        options.scales.y1.type = "logarithmic";
                        options.scales.y1.title.text = intl.formatMessage({
                            id: "timechart.yaxis.count",
                            defaultMessage: 'Anzahl [#]',
                            description: "Y-Axis-Label for count.",
                        })

                        options.scales.x.title.text = chartTypeNames[this.state.chartOptions.type]
                            + " [" + durationLabel() + "]"
                        options.scales.y1.ticks.color = axis1Color;
                        options.scales.y1.title.color = axis1Color;

                        options.scales.y1.afterBuildTicks = (axis) => {
                            // console.log(axis);
                            // axis.min = 0;
                            axis.ticks = axis.ticks.filter(t => t.value >= 1)
                        }
                    }

                    break;
                }
            }

            return (
                <div id="chartWrapper">
                    {this.state.displayOptions &&
                        <div id="chartOptions">
                            <Select<ChartType>
                                activeItem={this.state.chartOptions.type}
                                //id="chart-type"
                                filterable={false}
                                items={['START_INTERVAL', 'COVER_INTERVAL', 'RUNTIME_DIST']}
                                popoverProps={{ minimal: true }}
                                onItemSelect={(type) => {
                                    if (this.state.chartOptions.type !== type) {
                                        console.log(type);
                                        this.setOptions({ type })
                                    }
                                }}
                                itemRenderer={(item: ChartType, { handleClick, modifiers }) => {
                                    return (
                                        <MenuItem
                                            active={modifiers.active}
                                            key={item}
                                            onClick={handleClick}
                                            text={chartTypeNames[item]}
                                        />
                                    )
                                }}
                            >
                                <Button
                                    text={this.state.chartOptions.type
                                        ? chartTypeNames[this.state.chartOptions.type]
                                        : intl.formatMessage({
                                            id: "ui.unselected",
                                            defaultMessage: "(No selection)",
                                            description: "Label for unset selection.",
                                        })}
                                    rightIcon="caret-down"
                                />
                            </Select>
                            <Checkbox
                                checked={this.state.chartOptions.clampToZero}
                                label={intl.formatMessage({
                                    id: "ui.clampToZero",
                                    defaultMessage: "Clamp scales to Zero",
                                    description: "Label to clamp scales to zero.",
                                })}
                                disabled={this.state.chartOptions.type === 'RUNTIME_DIST'}
                                onChange={() => {
                                    this.setOptions({ clampToZero: !this.state.chartOptions.clampToZero })
                                }}
                            />
                            <Checkbox
                                checked={this.state.chartOptions.fixMinMax}
                                label={intl.formatMessage({
                                    id: "ui.fixMinMax",
                                    defaultMessage: "Fix vertical min/max",
                                    description: "Label to fix vertical min/max.",
                                })}
                                disabled={this.state.chartOptions.type === 'RUNTIME_DIST'}
                                onChange={() => {
                                    const fixMinMax = !this.state.chartOptions.fixMinMax;
                                    let optionsUpdate: object = { fixMinMax };
                                    if (fixMinMax && options.scales && options.scales.y1) {
                                        const minmaxDuration: MinMax = { min: options.scales.y1.min as number, max: options.scales.y1.max as number }
                                        optionsUpdate = { ...optionsUpdate, minmaxDuration }

                                        if (options.scales.y2) {
                                            const minmaxNumber: MinMax = { min: options.scales.y2.min as number, max: options.scales.y2.max as number }
                                            optionsUpdate = { ...optionsUpdate, minmaxNumber }
                                        }
                                        this.setOptions(optionsUpdate);
                                    }
                                    else {
                                        this.setOptions({ fixMinMax })
                                    }
                                }}
                            />
                        </div>
                    }
                    <div id="chartContent">
                        <Bar
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data={data as any}
                            options={options}
                        />
                        <Button
                            className={"btn-options"}
                            icon="cog"
                            onClick={() => this.setState({ ...this.state, displayOptions: !this.state.displayOptions })}
                        />
                    </div>
                </div>
            );
        }
        else {
            return <div><span>Waiting for Data...</span></div>
        }
    }
}
const v = injectIntl(TimeChart);
export { v as TimeChart, ChartType as ChartTypes, defaultChartType };