import { SHORT_UNIT } from "@insight/common/timescale/timeunits.js";
import { IntlShape } from "react-intl";

type TimeUnitLabelsObject = Record<SHORT_UNIT, string>;
export type TimeUnitLabels ={
    short: TimeUnitLabelsObject,
    singular: TimeUnitLabelsObject,
    plural: TimeUnitLabelsObject
}

export function getTimeLabels(intl: IntlShape): TimeUnitLabels {
    return {
        short: {
            "y": intl.formatMessage({
                id: "timechart.yaxis.unit.year",
                defaultMessage: 'y',
                description: "Y-Axis-Label for year time units.",
            }),
            "mo": intl.formatMessage({
                id: "timechart.yaxis.unit.month",
                defaultMessage: 'm',
                description: "Y-Axis-Label for month time units.",
            }),
            "w": intl.formatMessage({
                id: "timechart.yaxis.unit.week",
                defaultMessage: 'w',
                description: "Y-Axis-Label for week time units.",
            }),
            "d": intl.formatMessage({
                id: "timechart.yaxis.unit.day",
                defaultMessage: 'd',
                description: "Y-Axis-Label for day time units.",
            }),
            "h": intl.formatMessage({
                id: "timechart.yaxis.unit.hour",
                defaultMessage: 'h',
                description: "Y-Axis-Label for hour time units.",
            }),
            "mi": intl.formatMessage({
                id: "timechart.yaxis.unit.minute",
                defaultMessage: 'min',
                description: "Y-Axis-Label for minute time units.",
            }),
            "s": intl.formatMessage({
                id: "timechart.yaxis.unit.second",
                defaultMessage: 'sec',
                description: "Y-Axis-Label for second time units.",
            }),
            "ms": intl.formatMessage({
                id: "timechart.yaxis.unit.millisecond",
                defaultMessage: 'ms',
                description: "Y-Axis-Label for millisecond time units.",
            })
        },
        singular: {
            "y": intl.formatMessage({
                id: "timechart.xaxis.unit.year",
                defaultMessage: 'Year',
                description: "X-Axis-Label for year time unit.",
            }),
            "mo": intl.formatMessage({
                id: "timechart.xaxis.unit.month",
                defaultMessage: 'Month',
                description: "X-Axis-Label for month time unit.",
            }),
            "w": intl.formatMessage({
                id: "timechart.xaxis.unit.week",
                defaultMessage: 'Calendar Week',
                description: "X-Axis-Label for week time unit.",
            }),
            "d": intl.formatMessage({
                id: "timechart.xaxis.unit.day",
                defaultMessage: 'Date',
                description: "X-Axis-Label for day time unit.",
            }),
            "h": intl.formatMessage({
                id: "timechart.xaxis.unit.hour",
                defaultMessage: 'Hour',
                description: "X-Axis-Label for hour time units.",
            }),
            "mi": intl.formatMessage({
                id: "timechart.xaxis.unit.minute",
                defaultMessage: 'Minute',
                description: "X-Axis-Label for minute time units.",
            }),
            "s": intl.formatMessage({
                id: "timechart.xaxis.unit.second",
                defaultMessage: 'Second',
                description: "X-Axis-Label for second time units.",
            }),
            "ms": intl.formatMessage({
                id: "timechart.xaxis.unit.milliseconds",
                defaultMessage: 'Millisecond',
                description: "X-Axis-Label for milliseconds time units.",
            })
        },
        plural: {
            "y": intl.formatMessage({
                id: "timechart.xaxis.dur.years",
                defaultMessage: 'Years',
                description: "X-Axis-Label for years time unit.",
            }),
            "mo": intl.formatMessage({
                id: "timechart.xaxis.dur.months",
                defaultMessage: 'Months',
                description: "X-Axis-Label for months time unit.",
            }),
            "w": intl.formatMessage({
                id: "timechart.xaxis.dur.weeks",
                defaultMessage: 'Weeks',
                description: "X-Axis-Label for weeks time unit.",
            }),
            "d": intl.formatMessage({
                id: "timechart.xaxis.dur.days",
                defaultMessage: 'Days',
                description: "X-Axis-Label for days time unit.",
            }),
            "h": intl.formatMessage({
                id: "timechart.xaxis.dur.hours",
                defaultMessage: 'Hours',
                description: "X-Axis-Label for hours time units.",
            }),
            "mi": intl.formatMessage({
                id: "timechart.xaxis.dur.minutes",
                defaultMessage: 'Minutes',
                description: "X-Axis-Label for minutes time units.",
            }),
            "s": intl.formatMessage({
                id: "timechart.xaxis.dur.seconds",
                defaultMessage: 'Seconds',
                description: "X-Axis-Label for seconds time units.",
            }),
            "ms": intl.formatMessage({
                id: "timechart.xaxis.dur.milliseconds",
                defaultMessage: 'Milliseconds',
                description: "X-Axis-Label for milliseconds time units.",
            })
        },

    }

}