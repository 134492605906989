import React from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import {
    Alignment, Card, Checkbox, Classes, Dialog, Button,
    Icon, Label, Menu, Navbar, NavbarGroup, MenuItem, Position, Popover,
    H5, Callout, PopoverInteractionKind,
} from '@blueprintjs/core';

import { GraphDisplay } from '../graph/GraphDisplay2.js';
import { GraphvizOptions } from 'd3-graphviz';
import { GraphInfo } from './GraphInfo.js'

import socketService from '../../classes/socketService.js';
import { DotGraph, SimplificationParameters } from '@insight/common/dot_graph/dotgraph.js';
import { downloadBlob, downloadSVG } from '../../classes/download.js';
import { sendRequest } from '../../classes/Request.js';
import Project from '@insight/common/interface/Project.js';
import { ISEEventCategory } from '@insight/common/ISECase/ISEEventCategory.js';
import { Activity2 } from '../WidgetProvider.js';
import { GraphInteractionController, GraphInteractionControlProps } from '../View/GraphInteractionController.js';
import { ISSlider } from './Slider.js';
import { StackStateView } from './StackState.js';

export type InteractiveGraphProps = {
    project: Project;
    graph: DotGraph,
    invertCaseSelection: () => void;
    intl: IntlShape
} & GraphInteractionControlProps;

interface GraphOptions extends SimplificationParameters {
    integrate: boolean; // if true, integrate events with same destinations event types
    eventCategories: ISEEventCategory[]; // shown event categories
    debug: boolean; // if true, show debug information on edges and vertices
}

interface DisplayOptions {
    mode: 'avg' | 'min' | 'max';
    showEdgeData: boolean;
    showVertexData: boolean;
}

type InteractiveGraphState = {
    simpleGraph: DotGraph; // the simplified graph
    serverAvailable: boolean;
    optionsOpen?: boolean;
    popover: {
        eventId: number,
        isOpen: boolean,
        top: number,
        left: number,
        position: Position,
    }
}

const defaults: GraphvizOptions = {
    scale: 1,
    tweenPrecision: 5,
    engine: 'dot',
    keyMode: 'title',
    convertEqualSidedPolygons: false,
    fade: true,
    growEnteringEdges: true,
    fit: true,
    tweenPaths: true,
    tweenShapes: true,
    useWorker: true,
    zoom: true,
};

const CONTROL_ID = "interactive_graph";
const EDGE_SLIDER_FILTER = "edge_slider";
const VERTEX_SLIDER_FILTER = "vertex_slider";

class InteractiveGraph extends React.Component<InteractiveGraphProps, InteractiveGraphState> {
    static iag_id = 1;

    graphRef: React.RefObject<HTMLDivElement>
    popOverRef: React.RefObject<HTMLDivElement>
    options: (GraphOptions & DisplayOptions);
    interactionController: GraphInteractionController;

    constructor(props: InteractiveGraphProps) {
        super(props)
        this.interactionController = props.interactionController;

        this.options = {
            minVertexTraversals: 1,
            minEdgeTraversals: 1,
            singleFinish: false,
            integrate: true,
            eventCategories: ["i", "m"],
            mode: 'avg',
            debug: false,
            showEdgeData: false,
            showVertexData: false,
            removeEmptyElements: true,
        }

        this.state = {
            simpleGraph: this.createSimplifiedGraph(this.props.graph),
            popover: {
                eventId: -1,
                isOpen: false,
                top: 0,
                left: 0,
                position: Position.RIGHT,
            },
            serverAvailable: false,
        };
        this.graphRef = React.createRef<HTMLDivElement>();
        this.popOverRef = React.createRef<HTMLDivElement>();

        props.interactionController.registerSelector(CONTROL_ID, {
            dimension: "process",
            kind: "in-graph",
            setState: (data) => {
                if (data !== undefined) {
                    this.setState({ ...this.state });
                }
            }
        })

        props.interactionController.registerGraphFilter(EDGE_SLIDER_FILTER, {
            setFilter: (data: unknown) => {
                this.options.minEdgeTraversals = typeof data === "number" ? data : 1;
                this.updateGraphAndState(this.props.graph);
            }
        })

        props.interactionController.registerGraphFilter(VERTEX_SLIDER_FILTER, {
            setFilter: (data: unknown) => {
                this.options.minVertexTraversals = typeof data === "number" ? data : 1
                this.updateGraphAndState(this.props.graph);
            }
        })
    }

    createSimplifiedGraph(graph: DotGraph) {
        if (graph) {
            const graphCopy = graph.copy();
            graphCopy.debug = this.options.debug;
            graphCopy.showEdgeData = this.options.showEdgeData;
            graphCopy.showVertexData = this.options.showVertexData;

            graphCopy.simplify(this.options); // remove edges and vertices according to sliders
            if (this.options.integrate) {
                graphCopy.integrate({ singleFinish: this.options.singleFinish });
            }
            graphCopy.filterToCategory(this.options.eventCategories);
            graphCopy.updateProperties();
            return graphCopy
        }
        else {
            return graph;
        }
    }

    updateGraphAndState(graph: DotGraph) {
        this.setState({ ...this.state, simpleGraph: this.createSimplifiedGraph(graph) });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    shouldComponentUpdate(nextProps: Readonly<InteractiveGraphProps>, nextState: Readonly<InteractiveGraphState>, nextContext: any): boolean {
        /**
         * if the graph has changed, don't render now but update the state and do the rendering then
         */
        if (nextProps.graph !== this.props.graph) {
            this.updateGraphAndState(nextProps.graph)
            return false;
        }
        else {
            return true;
        }
    }


    /** @todo: find out correct types */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    socketListener = (sockState: any) => {
        this.setState({ ...this.state, serverAvailable: sockState.matches('connected') })
    }

    componentDidMount() {
        socketService.onTransition(this.socketListener)
    }

    componentWillUnmount() {
        socketService.off(this.socketListener);
    }

    /* --------------------------------------------------------------------------------
       E V E N T   H A N D L E R S
       -------------------------------------------------------------------------------- */

    visitGraphNodes = (svgElement: EventTarget | null, visitCB: (node: Element) => void) => {
        if (svgElement instanceof SVGElement) {
            const elems = svgElement.getElementsByTagName('title');
            if (elems !== null && elems[0].textContent) {
                const markerTitle = elems[0].textContent.split('_')[0];
                const graphElement = elems[0].closest('.graph-display');
                if (graphElement !== null) {
                    InteractiveGraph.styleNodes(graphElement, markerTitle, visitCB)
                }
            }
            else {
                throw new Error(`Node not found`);
            }
        }
    }

    /**
     * Set 'hoover' class on nodes with the same id than that which has been entered by the mouse
     * @param {Event} event
     * @param {DOMElement} node
     * @param {DOMElement} graphElement
     * @param {DotGraph} graph
     */
    mouseEntersNode: EventListener = (event: Event) => {
        this.visitGraphNodes(event.target, (node) => {
            const _class = node.getAttribute('class');
            const mouseEvent = (event as unknown as MouseEvent);
            if (_class !== null) {
                node.setAttribute('class', (_class ? _class : '') + ' hoover');
                if (mouseEvent.target instanceof SVGGElement) {
                    const g = mouseEvent.target;
                    const svg = g.ownerSVGElement;
                    if (svg !== null) {
                        const point = svg.createSVGPoint();
                        const matrix = g.getScreenCTM();
                        if (matrix !== null) {
                            const bbox = g.getBBox();
                            const div = svg.parentElement as HTMLDivElement;
                            const divRect = div.getBoundingClientRect();
                            let position: Position;
                            if (mouseEvent.clientX > divRect.left + divRect.width / 2) {
                                /* entered right of middle of container, position popover left */
                                point.x = bbox.x - 10;
                                position = Position.LEFT;
                            }
                            else {
                                /* entered left of middle of container, position popopver right */
                                point.x = bbox.x + bbox.width + 10;
                                position = Position.RIGHT;
                            }
                            point.y = bbox.y + bbox.height / 2;

                            const pointInDiv = point.matrixTransform(matrix);
                            const { x, y } = { x: pointInDiv.x - divRect.left, y: pointInDiv.y - divRect.top }

                            const elements = mouseEvent.target.getElementsByTagName("a")
                            if (elements.length === 1) {
                                const a = elements.item(0);
                                if (a !== null) {
                                    const __id = this.getGraphElementId(a);
                                    if (__id !== null) {
                                        const simpleGraphVertex = this.state.simpleGraph.vertices.array.find(v => v.__id === __id);
                                        if (simpleGraphVertex) {
                                            this.setState({
                                                ...this.state,
                                                popover: {
                                                    isOpen: true,
                                                    eventId: simpleGraphVertex.eventId,
                                                    top: y,
                                                    left: x,
                                                    position,
                                                }
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else {
                throw new Error(`class attribute not found`);
            }
        })
    }

    mouseLeavesNode: EventListener = (event: Event) => {
        this.visitGraphNodes(event.target, (node) => {
            const _class = node.getAttribute('class');
            if (_class !== null) {
                node.setAttribute('class', _class.replace('hoover', '').trim());
                this.setState({ ...this.state, popover: { ...this.state.popover, isOpen: false } })
            }
            else {
                throw new Error(`class attribute not found`);
            }
        })
    }

    private getGraphElementId(element: EventTarget | null): number | null {
        let id: number | null = null;
        let sId: string | null = null;
        if (element instanceof SVGElement) {
            const a: Element | null = element.closest('a');
            if (a !== null) {
                let href = a.getAttribute('xlink:href'); // Graphviz 8.05
                if (href === null) {
                    href = a.getAttribute('href'); // Graphviz 7.05
                }
                sId = (new URL("http://a.b" + href)).searchParams.get('__id');
            }
            if (sId !== null) {
                id = parseInt(sId);
            }
        }
        return id;
    }

    clickOnEdge: EventListener = (event: Event) => {
        const __id = this.getGraphElementId(event.target);
        if (__id !== null) {
            const graphEdge = this.state.simpleGraph.edges.array.find(edge => edge.__id === __id);
            if (graphEdge !== undefined) {
                const caseIds = graphEdge.cases.array.map(c => c.case_id);
                this.options = {
                    ...this.options,
                    minEdgeTraversals: 1,
                    minVertexTraversals: 1
                }
                this.props.interactionController.select({
                    caseIds,
                    selectorId: CONTROL_ID,
                    selectorState: {},
                });
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    clickOnNode: EventListener = (event: Event) => {
        /** the target will be a visible child of the group node, i.e. text or path */
        const __id = this.getGraphElementId(event.target);
        if (__id !== null) {
            const graphVertex = this.state.simpleGraph.vertices.array.find(v => v.__id === __id);
            if (graphVertex !== undefined) {
                let edges = graphVertex.incoming;
                if (edges.length === 0) edges = graphVertex.outgoing; // for start nodes
                // get unique case ids
                const caseIds = edges.reduce<Set<number>>((r, edge) => {
                    for (const c of edge.cases.array) {
                        r.add(c.case_id);
                    }
                    return r;
                }, new Set<number>())
                this.options = {
                    ...this.options,
                    minEdgeTraversals: 1,
                    minVertexTraversals: 1
                }
                this.props.interactionController.select({
                    caseIds: [...caseIds],
                    selectorId: CONTROL_ID,
                    selectorState: {},
                });
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    /**
     * A helper function to find all SVG nodes (representing vertexes of the process graph) which
     * have the same node id but different sources.
     * @param {DOMElement} graphElement The element selected in the SVG
     * @param {string} markerTitle The first part of the element title to search for (before '_')
     * @param {Function} styleFunction The function to style the element
     */
    static styleNodes(graphElement: Element, markerTitle: string, styleFunction: (node: Element) => void) {
        const nodes = graphElement.getElementsByClassName('node');
        for (const node of nodes) {
            const elems = node.getElementsByTagName('title');
            if (elems !== null && elems[0].textContent) {
                const title = elems[0].textContent.split('_')[0];
                if (title === markerTitle) {
                    styleFunction(node);
                }
            }
            else {
                throw new Error(`<title> Element not found`);
            }
        }
    }

    showEventCategory(cat: ISEEventCategory, show: boolean) {
        const s = new Set<ISEEventCategory>(this.options.eventCategories);
        if (show) s.add(cat); else s.delete(cat);
        this.options.eventCategories = Array.from(s);
        this.updateGraphAndState(this.props.graph);
    }

    downloadSelectedCases = () => {
        if (this.state.simpleGraph) {
            const caseIds = this.state.simpleGraph.casesInfo.array.flatMap(_case => [_case.id]);

            const query = new URLSearchParams({
                eventsfile: this.props.project.eventsFilePath,
            });

            sendRequest<Blob, number[]>("cases_csv?" + query.toString(), {
                method: "post",
                data: caseIds,
                responseType: "blob"
            }).then(blob => downloadBlob(blob, "cases.csv"));
        }
        else {
            throw new Error(`simplegraph is null`);
        }
    }

    downloadGraphSVG = () => {
        if (this.graphRef && this.graphRef.current) {
            let graph: SVGSVGElement = this.graphRef.current.getElementsByClassName('graph-display')[0].getElementsByTagName('svg')[0];
            graph = graph.cloneNode(true) as SVGSVGElement;

            // reset pan/zoom to initial graphviz default
            const vb = graph.getAttribute('viewBox');
            if (vb !== null) {
                const height = parseFloat(vb.split(' ')[3])
                graph.querySelector('#graph0')?.setAttribute('transform', `translate(4, ${height - 4})`) // 4pt margin

                // try to find and remove the white background which GraphViz generates
                graph.querySelector('#graph0 polygon')?.remove();
                downloadSVG(graph);
            }
        }
        else {
            throw new Error(`ref element is null or has no current element`);
        }
        return;
    }

    render() {
        console.log(`*** Render InteractiveGrph`)
        const intl = this.props.intl;

        /**
         * compute label values and stepsize for sliders based on the orginal graph
         */
        let label_max = Math.max(this.props.graph.properties.maxEdgeTraversals * 0.20, this.options.minEdgeTraversals, 10);

        const magnitude = Math.pow(10, Math.floor(Math.log10(label_max)));
        label_max = Math.ceil(label_max / magnitude) * magnitude;
        const tick = Math.max((label_max / (magnitude / 2) <= 10 ? magnitude / 2 : magnitude), 1);
        const labelValues = [1];
        let labelValue = tick;
        while (labelValue <= label_max) {
            labelValues.push(labelValue);
            labelValue += tick;
        }

        /**
         * set graph display
         */
        let display: React.ReactElement;
        if (this.state.simpleGraph.casesInfo.length === 0) {
            display = <div id="warning">
                <Callout
                    intent="warning"
                    title={intl.formatMessage({
                        id: "info.title",
                        defaultMessage: "Attention",
                        description: "Cause attention from the user"
                    })}
                >
                    {intl.formatMessage({
                        id: "info.no_selection",
                        defaultMessage: "No cases match the chosen selection.",
                        description: "Information to user that no cases are selected"
                    })}
                </Callout>
            </div >
        }
        else if (this.state.simpleGraph.edges.length > 512) {
            display = <div id="warning">
                <Callout
                    intent="warning"
                    title={intl.formatMessage({
                        id: "view.complexity_warning.title",
                        defaultMessage: "Warning",
                        description: "Whatever translates to 'Warning'"
                    })}
                >
                    {intl.formatMessage({
                        id: "view.complexity_warning",
                        defaultMessage: "Data too complex. Choose variant(s) or apply more filtering.",
                        description: "Complexity warning if data is too complex"
                    })}
                </Callout>
            </div>
        }
        else {
            display = <GraphDisplay
                graph={this.state.simpleGraph}
                options={defaults}
                className="graph-display"
                nodeMouseEnter={this.mouseEntersNode}
                nodeMouseLeave={this.mouseLeavesNode}
                edgeMouseClick={this.clickOnEdge}
                nodeMouseClick={this.clickOnNode}
                mode={this.options.mode}
            />
        }

        let nodePopOverContent: React.ReactElement = <></>
        if (this.state.popover.isOpen) {
            const simpleGraph = this.state.simpleGraph;
            const filteredGraph = simpleGraph.source;
            if (filteredGraph !== undefined) {
                const baseGraph = filteredGraph.source;
                const simpleOccurence = simpleGraph.eventOccurances(this.state.popover.eventId);
                const filteredOccurence = filteredGraph.eventOccurances(this.state.popover.eventId);
                const baseOccurence = baseGraph?.eventOccurances(this.state.popover.eventId);

                let msg: string;
                if (simpleOccurence === filteredOccurence) {
                    if (filteredOccurence === baseOccurence) {
                        msg = intl.formatMessage({
                            id: "interactgr.nodepopup.nochange",
                            defaultMessage: "Occurs {baseOccurence} times. No filters or simplification applied.",
                        }, { baseOccurence })
                    }
                    else {
                        msg = intl.formatMessage({
                            id: "interactgr.nodepopup.filtered",
                            defaultMessage: "Filtered to {filteredOccurence} times, all occurences shown. Unfiltered occurence is {baseOccurence}."
                        }, { filteredOccurence, baseOccurence })
                    }
                }
                else if (filteredOccurence === baseOccurence) {
                    msg = intl.formatMessage({
                        id: "interactgr.nodepopup.simplified",
                        defaultMessage: "Simplified to {simpleOccurence} occurences, no filters applied. Unfiltered occurence ist {baseOccurence}.",
                    }, { simpleOccurence, baseOccurence })
                }
                else {
                    msg = intl.formatMessage({
                        id: "interactgr.nodepopup.simplifiedAndFiltered",
                        defaultMessage: "Simplified to {simpleOccurence} of {filteredOccurence} filtered occurences. Unfiltered occurence ist {baseOccurence}.",
                    }, { simpleOccurence, filteredOccurence, baseOccurence })
                }
                if (baseGraph !== undefined) {
                    nodePopOverContent =
                        <Card style={{ minWidth: "20em" }} compact={true}>
                            {msg}
                        </Card>
                }

            }
        }

        const numberOfFilteredCases = this.props.graph.casesInfo.length - this.state.simpleGraph.casesInfo.length;

        const activeFilters = this.interactionController.history.find(() => true);
        return <div
            className="interactive-graph"
            ref={this.graphRef}
        >
            <Navbar>
                <NavbarGroup>
                    <Popover
                        minimal={true}
                        position={Position.BOTTOM_LEFT}
                        hasBackdrop={true}
                        content={
                            <Menu>
                                <MenuItem
                                    className={Classes.MINIMAL}
                                    text={intl.formatMessage({
                                        id: "intactgr.dl_svg",
                                        defaultMessage: "Download Graph SVG",
                                        description: "Label of a menu button to download the SVG file of a shown graph."
                                    })}
                                    onClick={this.downloadGraphSVG.bind(this)}
                                    disabled={!this.state.serverAvailable}
                                />
                                <MenuItem
                                    className={Classes.MINIMAL}
                                    text={intl.formatMessage({
                                        id: "intactgr.dl_cases",
                                        defaultMessage: "Download selected cases .CSV",
                                        description: "Label of a menu button to download a CSV of the selected cases."
                                    })}
                                    onClick={this.downloadSelectedCases}
                                    disabled={!this.state.serverAvailable}
                                />
                            </Menu>
                        }
                    >
                        <Button
                            className={Classes.MINIMAL}
                            icon="import"
                            text={intl.formatMessage({
                                id: "intactgr.dl",
                                defaultMessage: "Downloads",
                                description: "Label of a menu button for a Downloads dropdown menu."
                            })}
                            disabled={!this.state.serverAvailable}
                        />
                    </Popover>
                    <StackStateView
                        interactionController={this.props.interactionController}
                        intl={intl}
                        invertCaseSelection={this.props.invertCaseSelection}
                    />
                </NavbarGroup>
                <NavbarGroup className="bp5-align-center">
                    <h4>
                        <FormattedMessage
                            id="intactgr.datasize"
                            defaultMessage="{cases} cases with {events} events selected."
                            description="Message how many cases and events are selected."
                            values={{
                                cases: this.props.graph.casesInfo.length.toLocaleString(intl.locale),
                                events: (this.props.graph.properties.eventCount).toLocaleString(intl.locale),
                            }}
                        />
                        {numberOfFilteredCases > 0 &&
                            <span className='filtered'>
                                <FormattedMessage
                                    id="intactgr.filteredsize"
                                    defaultMessage=" {filtered} cases hidden, {shown} shown."
                                    description="Message how many cases and events are selected."
                                    values={{
                                        filtered: (this.props.graph.casesInfo.length - this.state.simpleGraph.casesInfo.length).toLocaleString(intl.locale),
                                        shown: this.state.simpleGraph.casesInfo.length.toLocaleString(intl.locale)
                                    }}
                                />
                            </span>
                        }
                    </h4>
                </NavbarGroup>
                <NavbarGroup align={Alignment.RIGHT}>
                    <Activity2 />
                    <GraphInfo
                        graph={this.state.simpleGraph}
                        mode={this.options.mode}
                        handleModeChange={(event: React.FormEvent<HTMLInputElement>) => {
                            this.options.mode = event.currentTarget.value as "avg" | "min" | "max";
                            this.updateGraphAndState(this.props.graph);
                        }}
                        handleEdgeDataDisplay={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.options.showEdgeData = event.target.checked;
                            this.updateGraphAndState(this.props.graph);
                        }}
                        handleVertexDataDisplay={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.options.showVertexData = event.target.checked;
                            this.updateGraphAndState(this.props.graph);
                        }}
                    />
                </NavbarGroup>
            </Navbar>
            <div className="graph-wrapper">
                <div className="active-filters">
                    {activeFilters && activeFilters.selections.has("process") && <Icon intent='primary' size={22} icon="data-lineage"></Icon>}
                    {activeFilters && activeFilters.selections.has("runtime") && <Icon intent='primary' size={22} icon="time"></Icon>}
                    {activeFilters && activeFilters.selections.has("org") && <Icon intent='primary' size={22} icon="office"></Icon>}
                    {activeFilters && activeFilters.selections.has("start_time_span") && <Icon intent='primary' size={22} icon="log-out"></Icon>}
                    {activeFilters && activeFilters.selections.has("cover_time_span") && <Icon intent='primary' size={22} icon="play"></Icon>}
                </div>
                <div className="sliders" style={{ zIndex: 10 }}>
                    {label_max > 0 &&
                        <Card className="slider-wrapper min-edge-slider" elevation={3}>
                            <Label>
                                <Icon icon="arrow-right" iconSize={16} style={{ "color": "#0B91DA" }} />
                                <FormattedMessage
                                    id="intactgr.avt"
                                    defaultMessage="Activities"
                                    description="Label for 'Activities' label for filter slider."
                                />
                            </Label>
                            <ISSlider
                                value={this.options.minEdgeTraversals}
                                max={label_max}
                                onChange={(value) => {
                                    this.props.interactionController.setGraphFilter(EDGE_SLIDER_FILTER, value)
                                }}
                            />
                        </Card>
                    }
                    {label_max > 0 &&
                        <Card className="slider-wrapper min-vtx-slider" elevation={3}>
                            <Label>
                                <Icon icon="symbol-rectangle" size={16} style={{ color: '#008971' }} />
                                <FormattedMessage
                                    id="intactgr.evt"
                                    defaultMessage="Events"
                                    description="Label for Events filter slider."
                                />
                            </Label>
                            <ISSlider
                                value={this.options.minVertexTraversals}
                                max={label_max}
                                onChange={(value) => {
                                    this.props.interactionController.setGraphFilter(VERTEX_SLIDER_FILTER, value)
                                }}
                            />
                        </Card>
                    }
                </div>
                {display}
                <div ref={this.popOverRef}
                    style={{
                        position: "absolute", // position relative to the container
                        // height: `0px`,
                        // width: `0px`,
                        top: `${this.state.popover.top}px`,
                        left: `${this.state.popover.left}px`,
                    }}
                >
                    <Popover
                        content={nodePopOverContent}
                        isOpen={this.state.popover.isOpen}
                        interactionKind={PopoverInteractionKind.HOVER}
                        position={this.state.popover.position}
                        usePortal={false} // avoids slow closing of Popover
                        modifiers={{ arrow: { enabled: false } }}
                    >
                        <div
                            style={{
                                height: `0px`,
                                width: `0px`,
                            }}
                        />
                    </Popover>
                </div>
            </div>
            <Button
                className={"btn-options"}
                icon="cog"
                onClick={() => {
                    this.setState({
                        ...this.state,
                        optionsOpen: true,
                    })
                }}
            />

            <Dialog
                icon="cog"
                title={intl.formatMessage({
                    id: "intactgr.options",
                    defaultMessage: "Options",
                    description: "Label of a menu button that openes an options dialog and the title of that dialog."
                })}
                onClose={() => {
                    this.setState({
                        ...this.state,
                        optionsOpen: false,
                    })
                }}
                isOpen={this.state.optionsOpen}
            >
                <Card>
                    <Checkbox
                        checked={this.options.integrate}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.options.integrate = event.target.checked;
                            this.updateGraphAndState(this.props.graph);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.intgr_bfr_simplf",
                            defaultMessage: "Integrate before simplification.",
                            description: "Option label to control graph integration before simplification."
                        })}
                    />
                    <Checkbox
                        checked={this.options.singleFinish && this.options.integrate}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.options.singleFinish = event.target.checked;
                            this.updateGraphAndState(this.props.graph);
                        }}
                        disabled={!this.options.integrate}
                        label={intl.formatMessage({
                            id: "intactgr.intgr_fnsh_nds",
                            defaultMessage: "Integrate finish nodes to one.",
                            description: "Option label to control integration of all finish nodes"
                        })}
                    />
                    <Checkbox
                        checked={this.options.removeEmptyElements}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.options.removeEmptyElements = event.target.checked;
                            this.updateGraphAndState(this.props.graph);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.remove_empty_elements",
                            defaultMessage: "Remove empty elements.",
                            description: "Option label to control removal of empty elements after simplification."
                        })}
                    />
                    <Checkbox
                        checked={this.options.debug}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.options.debug = event.target.checked;
                            this.updateGraphAndState(this.props.graph);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.debug",
                            defaultMessage: "Show verbose graph labels.",
                            description: "Option label to show verbose graph labels for debugging purposes."
                        })}
                    />

                    <H5><FormattedMessage
                        id="intactgr.event_selection"
                        defaultMessage="Select shown events"
                        description="Title for Dialog section to select shown events."
                    /></H5>

                    <Checkbox
                        checked={this.options.eventCategories.includes("i")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.showEventCategory("i", event.target.checked);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.inf_flw",
                            defaultMessage: "Show information events.",
                            description: "Option label to show information events"
                        })}
                    />
                    <Checkbox
                        checked={this.options.eventCategories.includes("m")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.showEventCategory("m", event.target.checked);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.mt_flw",
                            defaultMessage: "Show material events.",
                            description: "Option label to show material events"
                        })}
                    />
                    <Checkbox
                        checked={this.options.eventCategories.includes("meta")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.showEventCategory("meta", event.target.checked);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.meta_flw",
                            defaultMessage: "Show meta events.",
                            description: "Option label to show meta events"
                        })}
                    />
                    <Checkbox
                        checked={this.options.eventCategories.includes("aux")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.showEventCategory("aux", event.target.checked);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.aux_flw",
                            defaultMessage: "Show auxilary events.",
                            description: "Option label to show auxiliary events"
                        })}
                    />
                </Card>
            </Dialog>
        </div >
    }
}
export default injectIntl(InteractiveGraph); // inject 'intl' prop into component