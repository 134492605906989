import { union } from './icons.js';
import { Decycler } from '@insight/common/decycler/Decycler.js';
import { CaseFilter } from './CaseFilter.js';
import { IntlShape } from 'react-intl';
import { CaseFilterType } from './CaseFilterType.js';
import { DotGraph } from '@insight/common/dot_graph/dotgraph.js';

export class CaseFilterUnion extends CaseFilter {

    execute(eventsfile: string, graph: DotGraph) {
        return Promise.all(this.children.map(child => {
            return child.execute(eventsfile, graph);
        }))
            .then(childResults => {
                const caseIds = childResults.reduce<Set<number>>(
                    (set, childFilterCaseIds) => new Set([...set, ...childFilterCaseIds]),
                    new Set<number>()
                );
                return [...caseIds];
            })
    }

    label(intl: IntlShape) {
        const msg = intl.formatMessage({
            id: "filter.msg.union",
            defaultMessage: "Cases of all {count} sub-filters",
            description: "",
        }, { count: this.children.length })
        return msg;
    }
}
Decycler.registerSerializableType(CaseFilterUnion);

new CaseFilterType({
    id: "union",
    name: intl => intl.formatMessage({
        id: "filter.def.union.name",
        defaultMessage: "Union",
        description: "Name of union filter.",
    }),
    description: intl => intl.formatMessage({
        id: "filter.def.union.description",
        defaultMessage: "Union of all child filters",
        description: "Description of union filter.",
    }),
    fieldDefinitions: [],
    childrenAllowed: true,
    icon: union,
    class: CaseFilterUnion,
})