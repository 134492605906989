import React from "react";
import { Button, ButtonGroup, Divider, Icon } from "@blueprintjs/core";
import { GraphInteractionController, StackState } from "../View/GraphInteractionController.js";
import { IntlShape } from "react-intl";

type StackStateProps = {
    interactionController: GraphInteractionController,
    intl: IntlShape,
    invertCaseSelection: ()=>void;
}

export const StackStateView = (props: StackStateProps) => {
    const back = () => {
        props.interactionController.goBack();
    }

    const fwd = () => {
        props.interactionController.goFwd()
        return;
    }

    const icons: React.JSX.Element[] = [];
    const stackState = props.interactionController.stackState();
    for (let key = 0; key < stackState.totalItems; key++) {
        let color = key < stackState.totalItems - stackState.localItems ? "normal" : "green";
        let icon = key === stackState.historyPos
            ? <Icon icon="selection" color={color} key={key} size={10} />
            : <Icon icon="circle" color={color} key={key} size={10} />
        icons.push(icon);
    }

    return props.interactionController.history.history.length === 0 ||
        <ButtonGroup >
            <Divider />
            <Button
                icon="trash"
                variant='minimal'
                onClick={() => {
                    props.interactionController.clearHistory();
                }}
            />
            <Button
                disabled={!props.interactionController.backwardPossible}
                onClick={back}
                variant="minimal"
                icon="undo"
            />
            <div className="stack-position">{icons}</div>
            <Button
                disabled={!props.interactionController.forwardPossible}
                onClick={fwd}
                variant="minimal"
                icon="redo"
            />
            <Button
                onClick={props.invertCaseSelection}
                variant="minimal"
                text={props.intl.formatMessage({
                    id: "intactgr.invert_select",
                    defaultMessage: "Invert selection",
                    description: "Label of a menu button to invert case selection."
                })}
            />
        </ButtonGroup>
}