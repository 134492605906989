import React from "react";
import { useIntl } from "react-intl";
import { Tab, Tabs } from "@blueprintjs/core";

import InteractiveGraph from "../interactive_graph/InteractiveGraph.js";
import { GridGroup, GridCell, IGridElementProps } from "../Grid/Grid.js";
import { VariantsTable } from "../VariantsTable/VariantsTable.js";
import { TimeChart } from "../TimeChart/TimeChart.js";
import { FilterManager } from "../CaseSelection/FilterManager.js";
import { InsightData } from "../DataProvider2.js"
import { DotGraph } from "@insight/common/dot_graph/dotgraph.js";
import { OrgTree } from "../OrgTree.js";
import { GraphInteractionController, MultiDimSelection } from "./GraphInteractionController.js";

export interface ViewProps extends IGridElementProps {
    dataProvisionContext: InsightData
}

type DataSelectionState = {
    reducedGraph: DotGraph | null; // the graph reduced by filtered cases
}

/**
 * Integrates the graph, chart and case selection components in the UI and manages
 * the case selection.
 */
export const View: React.FC<ViewProps> = (props: ViewProps) => {

    const intl = useIntl();

    const [state, setState] = React.useState<DataSelectionState>(() => {
        return {
            reducedGraph: null,
        }
    })

    /**
     * Filter a graph based on the org selection and the functional selection.
     * @param selector
     * @returns
     */
    const reduceGraph = React.useCallback((selection: MultiDimSelection | undefined) => {

        /** all case ids as default selection */
        let caseIds = props.dataProvisionContext.graph.casesInfo.array.map(ci => ci.id);
        if (selection !== undefined) {
            /* create intersection of all selected selector dimensions */
            for (const key of selection.keys()) {
                const selectionDim = selection.get(key);
                if (selectionDim !== undefined && selectionDim.caseIds !== undefined) {
                    caseIds = caseIds.filter(cid => selectionDim.caseIds!.includes(cid));
                }
            }
        }

        /**
         * reduce graph to finally selected cases - new algorithm from Sept. 2024
         */
        const filteredGraph = props.dataProvisionContext.graph.reduceToCases(caseIds);
        filteredGraph.source = props.dataProvisionContext.graph;  // remember the original graph as source of the reduced graph

        /**
         * alternative implementation to get to the filtered graph. Much slower!
         * But let it stay in the code as a reminder. ah 30.9.24
         */
        // const filteredGraph = props.dataProvisionContext.graph.copy();
        // if (selection.caseIds.length > 0) filteredGraph.filterToCases(selection.caseIds);

        /**
         * pass reduced graph to state and trigger redraw
         */
        setState({ reducedGraph: filteredGraph })
    }, [props.dataProvisionContext.graph]);

    /**
     * The interaction controller is created during the first call of the component.
     * However, in EACH call, the current version of the reduceGraph function needs
     * to be available to the interaction controller.
     */
    const interactionController = React.useRef(new GraphInteractionController(reduceGraph));

    interactionController.current.doSelect = React.useMemo(() => {
        return reduceGraph
    }, [props.dataProvisionContext.graph]);

    /**
     * on change of the data provision, reset the state of the View component after render
     */
    React.useMemo(() => {
        setState({
            reducedGraph: null,
        })
        interactionController.current.clearHistory();
    }, [props.dataProvisionContext])


    const invertCaseSelection = () => {
        const context = state; // variantsGraph and variantsTable data are local to this component
        if (context.reducedGraph !== null) {
            let caseIds = props.dataProvisionContext.graph.casesInfo.map(c => c.id);
            const currentSelection = context.reducedGraph.casesInfo.map(c => c.id);
            caseIds = caseIds.filter(c => !currentSelection.includes(c));
            interactionController.current.select({ caseIds, selectorId: "interactive_graph", selectorState: null })
        }
    }

    /**
     * Execute once after first render
     */
    React.useEffect(() => {
        console.log(`*** Render View ${props.id}`);
    })

    if (state && props.dataProvisionContext.project) {
        let timeChartGridContent;
        let processGridContent;
        if (state.reducedGraph === null) {
            timeChartGridContent = null;
            processGridContent = null;
        }
        else {
            timeChartGridContent = <TimeChart
                project={props.dataProvisionContext.project}
                graph={state.reducedGraph}
                interactionController={interactionController.current}
            />;
            processGridContent = <InteractiveGraph
                project={props.dataProvisionContext.project}
                graph={state.reducedGraph}
                invertCaseSelection={invertCaseSelection}
                interactionController={interactionController.current}
            />
        }

        const tabs: React.ReactElement[] = [];
        if (props.dataProvisionContext.orgStructure && props.dataProvisionContext.variants) {
            tabs.push(
                <Tab
                    key="variants-tab"
                    id="variants-tab"
                    title={intl.formatMessage({
                        id: "view.tab.variants",
                        defaultMessage: "Variants",
                        description: "Label for 'Variants'"
                    })}
                    panel={<VariantsTable
                        variants={props.dataProvisionContext.variants}
                        heading={false}
                        interactionController={interactionController.current}
                    />}
                />
            )

            tabs.push(
                <Tab
                    key="filter-tab"
                    id="filter-tab"
                    title={intl.formatMessage({
                        id: "view.tab.filters",
                        defaultMessage: "Filters",
                        description: "Label for 'Filters'"
                    })}
                    panel={<FilterManager
                        caseFilters={props.dataProvisionContext.caseFilters ? props.dataProvisionContext.caseFilters : []}
                        graph={props.dataProvisionContext.graph}
                        project={props.dataProvisionContext.project}
                        heading={false}
                        interactionController={interactionController.current}
                    />}
                />
            )
        }

        const gridCells: React.ReactElement[] = [];
        if (props.dataProvisionContext.orgStructure) {
            let key = ["selectors", props.id].join("_")
            gridCells.push(
                <GridCell
                    key={key}
                    id={key}
                    top={1}
                    left={1}
                    bottom={6}
                    right={1}
                >
                    <Tabs className="selectors-grid" >{tabs}</Tabs>
                </GridCell>
            )
            key = ["orgtree", props.id].join("_");
            gridCells.push(
                <GridCell
                    key={key}
                    id={key}
                    top={7}
                    left={1}
                    bottom={10}
                    right={1}
                >
                    <OrgTree
                        isorg={props.dataProvisionContext.orgStructure}
                        project={props.dataProvisionContext.project}
                        interactionController={interactionController.current}
                    />
                </GridCell>
            )
        }
        else {
            if (props.dataProvisionContext.variants) {
                let key = ["filter-grid", props.id].join("_");
                gridCells.push(
                    <GridCell
                        key={key}
                        id={key}
                        top={1}
                        left={1}
                        bottom={5}
                        right={1}
                    >
                        <FilterManager
                            caseFilters={props.dataProvisionContext.caseFilters ? props.dataProvisionContext.caseFilters : []}
                            graph={props.dataProvisionContext.graph}
                            project={props.dataProvisionContext.project}
                            heading={true}
                            interactionController={interactionController.current}
                        />
                    </GridCell>
                )
                key = ["variants-grid", props.id].join("_");
                gridCells.push(
                    <GridCell
                        key={key}
                        id={key}
                        top={6}
                        left={1}
                        bottom={10}
                        right={1}
                    >
                        <VariantsTable
                            variants={props.dataProvisionContext.variants}
                            heading={true}
                            interactionController={interactionController.current}
                        />
                    </GridCell>
                )
            }
            else {
                const key = ["filter-grid", props.id].join("_");
                gridCells.push(
                    <GridCell
                        key={key}
                        id={key}
                        top={1}
                        left={1}
                        bottom={10}
                        right={1}
                    >
                        <FilterManager
                            caseFilters={props.dataProvisionContext.caseFilters ? props.dataProvisionContext.caseFilters : []}
                            graph={props.dataProvisionContext.graph}
                            project={props.dataProvisionContext.project}
                            heading={true}
                            interactionController={interactionController.current}
                        />
                    </GridCell>
                )
            }
        }

        gridCells.push(
            <GridCell
                key="chart-grid"
                id="chart-grid"
                top={1}
                left={2}
                bottom={2}
                right={5}
                className="chart"
            >
                {timeChartGridContent}
            </GridCell>
        )

        gridCells.push(
            <GridCell
                key="process-grid"
                id="process-grid"
                top={3}
                left={2}
                bottom={10}
                right={5}
                className="process"
            >
                {processGridContent}
            </GridCell>
        )

        return (
            <GridGroup {...props} >
                {gridCells}
            </GridGroup>
        );
    } else {
        return <div>No data available</div>;
    }
}